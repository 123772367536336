import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight, HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FaFacebookF, FaXTwitter, FaLinkedinIn, FaWhatsapp, FaPhoneVolume } from "react-icons/fa6";
import { MdEmail, MdPinDrop } from "react-icons/md";

const StyleGuide = () => {
    return (
        <div className="container my-5">
            <Link to="/demo" >Demo</Link>
            <h3 className="my-5">Typography</h3>
            <div className="text-center mb-5">
                <h1>Roboto H1, bold 48px</h1>
                <h2>Roboto H2, semi-bold 36px</h2>
                <h3>Roboto H3, semi-bold 24px</h3>
                <h4>Roboto H4, regular 16px</h4>
                <h5>Roboto H5, regular 14px</h5>
            </div>
            <h3 className="my-5">Buttons with Colors</h3>
            <div className="col_centered" style={{ gap: "20px" }}>
                <button className="btn_primary_1">Primary 1</button>
                <button className="btn_primary_gradient">Primary Gradient</button>
                <button className="btn_primary_2">Primary 2</button>
                <button className="btn_secondary_1">Secondary 1</button>
                <button className="btn_secondary_2">Secondary 2</button>
            </div>
            <h3 className="my-5">Icons</h3>
            <div className="row_center" style={{ gap: "20px" }}>
                <span className="icon_round">
                    <FaFacebookF size={20} />
                </span>
                <span className="icon_round">
                    <FaXTwitter size={20} />
                </span>
                <span className="icon_round">
                    <FaLinkedinIn size={20} />
                </span>
                <span className="icon_round">
                    <FaWhatsapp size={20} />
                </span>
                <span className="icon_parent">
                    <MdEmail size={50} />
                </span>
                <span className="icon_parent">
                    <FaPhoneVolume size={40} />
                </span>
                <span className="icon_parent">
                    <MdPinDrop size={50} />
                </span>
                <button className="arrow_btn">
                    <HiOutlineArrowNarrowLeft size={28} />
                </button>
                <button className="arrow_btn">
                    <HiOutlineArrowNarrowRight size={28} />
                </button>
            </div>
        </div>
    );
};

export { StyleGuide }

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        />
    );
}

export var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay:true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ],
    appendDots: dots => (
        <div
            style={{
                position: "relative",
                width: "100%"
            }}
        >
            <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                <ul style={{ margin: "0px" }} className="t_dots"> {dots} </ul>
            </div>
        </div>
    )
}

export var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay:true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ],
    appendDots: dots => (
        <div
            style={{
                position: "relative",
                width: "100%"
            }}
        >
            <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "5px" }}>
                <ul style={{ margin: "0px" }} className="t_dots"> {dots} </ul>
            </div>
        </div>
    )
}

export var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay:true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ],
    appendDots: dots => (
        <div
            style={{
                position: "relative",
                width: "100%"
            }}
        >
            <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "5px" }}>
                <ul style={{ margin: "0px" }} className="t_dots"> {dots} </ul>
            </div>
        </div>
    )
}

export var settings4 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay:true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ],
    appendDots: dots => (
        <div
            style={{
                position: "relative",
                width: "100%",
                paddingTop: "25px",
                paddingBottom: "25px"
            }}
        >
            <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                <ul style={{ margin: "0px" }} className="t_dots"> {dots} </ul>
            </div>
        </div>
    )
}

// selectStyles.js
export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px 0px #00000029',
        border: '0.5px solid #407BFF',
        // width: state.selectProps.width || 'auto',
        fontSize: state.selectProps.fontSize || '16px',
        fontWeight: '400',
        // '&:hover': {
        //     border: '0.5px solid #407BFF'
        // },
    }),
    // menu: (provided) => ({
    //     ...provided,
    //     zIndex: 9999,
    //     backgroundColor: 'lightgrey'
    // }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#407BFF' : state.isFocused ? '#D2E7F0' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:hover': {
            backgroundColor: '#D2E7F0',
            color: 'black'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'grey'
    }),
};

