import React from 'react';
import './CustomRadioButton.css';

const CustomRadioButton = ({ label, value, setValue, checked }) => {

    const handleClick = () => {
        console.log(value, checked);
        setValue(value);
    }

    return (
        <div className="radio-container" onClick={handleClick}>
            <span className='circle'>
                <span className={`${checked ? 'dot' : ''}`}></span>
            </span>
            <h4>{label}</h4>
        </div>
    );
};

export { CustomRadioButton };
