import React from "react";
import { Footer } from "../components/Footer";
import { settings, settings2 } from "./StyleGuide";
import Slider from "react-slick";
import { FaAnglesRight } from "react-icons/fa6";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const HomeNurse = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Home Nurse'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="expert">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Expert Care<br /> Close To Home</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid mobile" src={require('./images/expert_responsive.webp')} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="nursing">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Nursing Services,<br /> Redefined For Modern Age</h2>
                            <h4>Our skilled and dedicated nurses would provide you personalized assistance
                                with daily activities, & emotional support, all in the comfort of your own home.
                                With a focus on your well-being and convenience, we strive to make your heal
                                -ing journey as smooth and comfortable as possible. We ensure that you rece
                                -ive the highest quality care in the most familiar environment.</h4>
                        </div>
                        <div className="col-md-6 right">
                            <img className="img-fluid big_img" src={require('./images/nursing.webp')} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="partner">
                <div className="container">
                    <h2>Your Partner In Recovery</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/post_surgery.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Post Surgery Care</h3>
                            <h4>Monitor vitals, wound care, mobility
                                assistance, respiratory care, fluid
                                management, & so on</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/elderly_care.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Elderly Care</h3>
                            <h4>Care planning, mobility assistance,
                                nutritional support, cognitive
                                stimulation, emotional support,
                                & so on</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/palliative_care.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Palliative Care</h3>
                            <h4>Care planning, symptom management,
                                medication management, advance
                                care planning, education & support,
                                & so onF</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/hospice_care.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Hospice Care</h3>
                            <h4>Care planning, symptom management,
                                end-of-life care, emotional support,
                                advance care planning, & so on</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/respite_care.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Respite Care</h3>
                            <h4>Care planning, assistance with activ
                                ities of daily living, monitoring vitals,
                                respite for caregivers, safety &
                                comfort</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/companionship.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Companionship</h3>
                            <h4>Emotional support, supporting
                                independence, cultural & spiritual
                                support, activities to alleviate
                                boredom, & so on</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/medication_management.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Medication Management</h3>
                            <h4>Medication administration, reconciliation,
                                counseling, monitoring for
                                adverse effects, refills, renewals,
                                and safety</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/safety.webp')} className="img-fluid" alt="post-surgery" />
                            <h3>Safety Concerns</h3>
                            <h4>Assessment of home environment, fall
                                prevention, medication safety, prevention
                                of infections, emergency preparedness,
                                monitoring vitals, & so on</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why_choose">
                <div className="container">
                    <h2>Why Choose Home Nurse Services By Arush</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/verified.webp')} />
                            <h3>Verified Background</h3>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/seamless.webp')} />
                            <h3>Seamless Substitution</h3>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/quick.webp')} />
                            <h3>Quick Scheduling</h3>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/licensed.webp')} />
                            <h3>Licensed Nursing Professionals</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="experience">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={require('./images/experience.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Experience The Difference</h2>
                            <h4>Our approach goes beyond traditional medical
                                care to encompass emotional support, companionship,
                                and empowerment. With innovative technology and
                                compassionate nursing, we're transforming the home
                                into a place of healing, comfort, and independence.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="differently">
                <div className="container">
                    <h2>We Do It Differently</h2>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <ul>
                                <li>
                                    <FaAnglesRight size={10} />
                                    Provide a seamless care continuum through our homecare services,
                                    backed by our specialized care centers.
                                </li>
                                <li>
                                    <FaAnglesRight size={10} />
                                    Protocol-driven care management
                                </li>
                                <li>
                                    <FaAnglesRight size={10} />
                                    Care delivered through a multi-disciplinary collaborative approach
                                </li>
                            </ul>
                            <button className="btn_primary_gradient">All these at convenient pricing</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require("./images/differently.webp")} className="img-fluid big_img" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Don’t Just Take Our Words For It</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="backed">
                <div className="container">
                    <h2>Trusted By Leading Hospitals</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/zoi.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/renova.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/wellness.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/apollo.png')} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};
export { HomeNurse };