import React from "react";
import './pages2.css';
import { Footer } from "../components/Footer";
import Slider from "react-slick";
import { FaAnglesRight } from "react-icons/fa6";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const HomeICU = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Home ICU Setup'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="transforming">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Transforming the ICU experience</h2>
                            <h2>From hospital rooms to home comforts</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/transforming_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="beds">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={require('./images/beds.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>No Hospital Beds, No Sterile Rooms</h2>
                            <h4>Transforming healthcare with our innovative home ICU setup. Say goodbye to hospital
                                stays and hello to comfort, convenience, and expert care tailored to your needs. Stay
                                connected to loved ones, minimize the risk of hospital-acquired infections, and enjoy
                                the comfort of your home while receiving expert medical attention.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cover">
                <div className="container">
                    <h2>We Cover</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Skilled Nursing Care</h3>
                        <h3 className="grid_item">Vital Monitoring</h3>
                        <h3 className="grid_item">IV Administration</h3>
                        <h3 className="grid_item">Catheter Care</h3>
                        <h3 className="grid_item">Infection Control</h3>
                        <h3 className="grid_item">Equipment Handling</h3>
                        <h3 className="grid_item">Doctor Consultation</h3>
                        <h3 className="grid_item">Ventilator Support</h3>
                    </div>
                </div>
            </section>
            <section className="support">
                <div className="container">
                    <h2>Support Your Journey To Recovery</h2>
                    <h3>ICU Setup serves the purpose of providing care to patients who are</h3>
                    <div className="grid_3">
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/critical.webp")} />
                            <h3>Critically Ill</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/post_surgery.webp")} />
                            <h3>Post Surgery Recovery</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/organ_failure.webp")} />
                            <h3>Organ Failure</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/cancer.webp")} />
                            <h3>Cancer Care</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/trauma.webp")} />
                            <h3>Multiple Trauma</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/transplant.webp")} />
                            <h3>Post – Transplant</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="medical">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="img-fluid big_img" src={require("./images/medical.png")} alt="medical" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Is Medical ICU Set Up A Critical Need?</h2>
                            <ul>
                                <li>
                                    <FaAnglesRight size={10} />
                                    With the rise of complex medical conditions and the
                                    growing demand for personalized care, medical ICU
                                    setups have emerged as essential components of modern
                                    healthcare infrastructure.
                                </li>
                                <li>
                                    <FaAnglesRight size={10} />
                                    It ensures that patients receive timely interventions,
                                    continuity of care, and access to the latest medical advancements.
                                </li>
                                <li>
                                    <FaAnglesRight size={10} />
                                    Plays a crucial role in relieving pressure on traditional hospital ICUs, which may be
                                    overcrowded or facing resource constraints.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="backed">
                <div className="container">
                    <h2>Backed By Top Hospitals</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/zoi.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/renova.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/wellness.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/apollo.png')} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Don’t Just Take Our Words For It</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { HomeICU };