import React from "react";
import "./Footer.css";
import { FaChevronRight } from "react-icons/fa";
import { FaFacebookF, FaXTwitter, FaLinkedinIn, FaWhatsapp, FaPhoneVolume } from "react-icons/fa6";
import { SlLocationPin } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();

    const handleNavigate = (url) => {
        window.scrollTo(0, 0);
        navigate(url);
    }

    return (
        <section className="footer">
            <div className="footer_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 footer_logo">
                            <span>
                                <img src={require("../../pages/images/footer_logo.png")} alt="logo" />
                                <h2>Logo here</h2>
                            </span>
                            <p className="footer_text">
                                Subscribe to out newsletter today to
                                receive latest news administrate cost
                                effective for tactical data.
                            </p>
                            <p><SlLocationPin /> 2478 Street City Ohio 90255</p>
                            <p><GoMail /> info@mediax.com</p>
                            <p><FiPhone />+(402) 763 282 46</p>
                        </div>
                        <div className="col-md-3 ms-auto">
                            <h3>Quick Links</h3>
                            <ul>
                                <li onClick={() => handleNavigate('/')}><FaChevronRight size={15} color="white" />Home</li>
                                <li><FaChevronRight size={15} color="white" />About Us</li>
                                <li><FaChevronRight size={15} color="white" />Blog</li>
                                <li onClick={() => handleNavigate('/join-our-network')}><FaChevronRight size={15} color="white" />Join Our Network</li>
                                <li><FaChevronRight size={15} color="white" />FAQ’s</li>
                                <li><FaChevronRight size={15} color="white" />Terms And Conditions</li>
                                <li><FaChevronRight size={15} color="white" />Contact Us</li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h3>Services</h3>
                            <ul>
                                {/* <details><summary>Reactjs</summary>Redux</details> */}
                                <li onClick={() => handleNavigate('/home-nurse')}><FaChevronRight size={15} color="white" />Home Nurse</li>
                                <li onClick={() => handleNavigate('/care-giver')}><FaChevronRight size={15} color="white" />Care Giver</li>
                                <li onClick={() => handleNavigate('/baby-care-services')}><FaChevronRight size={15} color="white" />Baby Care</li>
                                <li onClick={() => handleNavigate('/diagnostics')}><FaChevronRight size={15} color="white" />Diagnostics</li>
                                <li onClick={() => handleNavigate('/home-health-checks')}><FaChevronRight size={15} color="white" />Health Checks</li>
                                <li onClick={() => handleNavigate('/radiology')}><FaChevronRight size={15} color="white" />Radiology</li>
                                <li onClick={() => handleNavigate('/physiotherapy')}><FaChevronRight size={15} color="white" />Physiotherapy</li>
                                <li onClick={() => handleNavigate('/doctor-consultation')}><FaChevronRight size={15} color="white" />Doctor Consultations</li>
                            </ul>
                        </div>
                        <div className="col-md-3 recent">
                            <h3>Recent  Blog Posts</h3>
                            <div className="blog">
                                <img src={require('../../pages/images/Container.png')} alt="blog" />
                                <div>
                                    <h4>Future Of Nursing in home healthcare</h4>
                                    <h5>Feb 20, 2021</h5>
                                </div>
                            </div>
                            <div className="blog">
                                <img src={require('../../pages/images/Container.png')} alt="blog" />
                                <div>
                                    <h4>Future Of Nursing in home healthcare</h4>
                                    <h5>Feb 20, 2021</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <p className="desktop">Copyright &copy; 2024 Example. All Rights Reserved. Powered By KriSanTec Solutions</p>
                    <p className="mobile">Copyright &copy; 2024 <a>Mediax.</a> All Rights Reserved.<br /> Powered By KriSanTec Solutions</p>
                    <div className="footer_social">
                        <div className="row_center">
                            <span className="icon_round">
                                <FaFacebookF size={20} />
                            </span>
                            <span className="icon_round">
                                <FaXTwitter size={20} />
                            </span>
                            <span className="icon_round">
                                <FaLinkedinIn size={20} />
                            </span>
                            <span className="icon_round">
                                <FaWhatsapp size={20} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export { Footer }