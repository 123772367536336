import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ReactQuillComponent({ SetData, name, Data, value, readOnly }) {
  const [text, setText] = useState('');
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean']
  ];
  const handleChange = (value) => {
    SetData({ ...Data, [name]: value });
  };
  return (
    <div>
      <ReactQuill modules={{ toolbar: toolbarOptions }} value={value} name={name} onChange={handleChange} readOnly={readOnly} />
    </div>
  );
}

export { ReactQuillComponent };
