import React, { useState, useEffect } from "react";
import { FormParser } from "../components/FormParser/FormParser";
import Masters from "../components/masters.json";
import axiosConfig from "../Service/axiosConfig";
const Profile = () => {

    const MastersModal = Masters.Profile;
    const [readOnly, setReadOnly] = useState(true);
    const [formData, setFormData] = useState({})
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const getUser = async () => {
        try {
            const response = await axiosConfig.get(`accounts/users/${localStorage.getItem('user_id')}/`);
            console.log(response.data);
            setFirstName(response.data.first_name);
            setLastName(response.data.last_name);
            let first_name = response.data.first_name || '';
            let last_name = response.data.last_name || '';
            const full_name = first_name + " " + last_name;
            setFormData({
                ...response.data,
                full_name: full_name.trim()
            });            
        } catch (error) {
            console.log(error);
        }
    }

    const formSubmit = async(data) => {
        try {
            let data_to_post = {
                ...data,
                first_name: data.full_name.split(' ')[0],
                last_name: data.full_name.split(' ')[1]
            }
            const response = await axiosConfig.put(`accounts/users/${localStorage.getItem('user_id')}/`, data);
            console.log(response.data);
            setReadOnly(true);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="patient-dashboard-child">
            <h3 className="desktop">Profile</h3>
            <div className="form-container">
                <FormParser
                    modelObject={MastersModal}
                    buttonTitle="Save"
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    formData={formData}
                    formSubmit={formSubmit}
                />
                {readOnly &&
                    <button className="btn_primary_gradient" onClick={() => setReadOnly(false)}>Edit</button>
                }
            </div>
        </div>
    );
};

export { Profile }