import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';
const Logout = () => {
  const Navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();
  useEffect(() => {
    localStorage.clear();
    setIsLoggedIn(false);
    return Navigate("/")
  }, [])
}
export default Logout;