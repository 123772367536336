import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  modalTitle: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    handleClose(state) {
      state.show = false;
    },
    handleShow(state) {
      state.show = true;
    },
    setModalTitle(state, action) {
      state.modalTitle = action.payload;
    }
  }
});

export const { handleClose, handleShow, setModalTitle, setIsRegistered } = modalSlice.actions;
export default modalSlice.reducer;
