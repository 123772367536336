import React from "react";
import Slider from "react-slick";
import { settings, settings2 } from "./StyleGuide";
import { Footer } from "../components/Footer";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';
const Radiology = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Radiology'));
        dispatch(handleShow());
    };
    return (
        <div>
            <section className="radiology">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Radiology Redefined</h2>
                            <h2>Home Based, Hassle-Free</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require("./images/radiology_mobile.webp")} className="img-fluid mobile" alt="medicines" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ride">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={require("./images/in_your.webp")} className="img-fluid big_img" alt="medicines" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>In Your Environment</h2>
                            <h3>On Your Schedule</h3>
                            <h4>
                                With "In Your Environment, On Your Schedule" as our motto, we
                                bring advanced radiology technology directly to your doorstep,
                                ensuring you can undergo essential tests without disrupting your
                                daily routine or compromising your comfor
                            </h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="medicines our">
                <div className="container">
                    <h2>Our Plethora Of Services</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">X-Ray</h3>
                        <h3 className="grid_item">ECG</h3>
                        <h3 className="grid_item">Sleep Study</h3>
                        <h3 className="grid_item">Holter Study</h3>
                        <h3 className="grid_item">Bone Density Screenings</h3>
                        <h3 className="grid_item">Doppler Tests</h3>
                        <h3 className="grid_item">Pacemaker Checks</h3>
                        <h3 className="grid_item">Ultrasounds</h3>
                    </div>
                </div>
            </section>

            <section className="premier">
                <div className="container">
                    <h2>Why We’re Premier Option</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Convenience</h3>
                        <h3 className="grid_item">Precision</h3>
                        <h3 className="grid_item">Sustainability</h3>
                        <h3 className="grid_item">Expediency</h3>
                    </div>
                </div>
            </section>

            <section className="say trans_hc">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Transforming Healthcare</h2>
                            <h4>
                                Utilizing state-of-the-art-digital technology, our system
                                captures high-resolution radiographic images with exceptional
                                clarity and detail. This digital capability ensures quick image
                                acquisition and instant viewing, enabling healthcare providers
                                to make informed decisions promptly.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require("./images/trans_hc.webp")} alt="trans_hc" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="support boon">
                <div className="container">
                    <h2>A Boon For</h2>
                    <div className="grid_3">
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/elderly.webp")} />
                            <h3>Elderly</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/bedridden.webp")} />
                            <h3>Bedridden</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/critically.webp")} />
                            <h3>Critically injured</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/broken.webp")} />
                            <h3>Broken limbs</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/post_surgical.webp")} />
                            <h3>Post Surgical Patients</h3>
                        </div>
                        <div className="grid_item">
                            <img className="img-fluid" src={require("./images/individuals.webp")} />
                            <h3>Individuals with terminal disease</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials settings">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Don’t Just Take Our Words For It</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export { Radiology }