import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const Physiotherapy = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Physiotherapy'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="your_home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Your Home, Our Clinic</h2>
                            <h2>Let’s Start Your Recovery Journey</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/your_home_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/rehab.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>Rehab Where You Relax</h2>
                            <h4>Our skilled therapists bring personalized care tailored to your needs,
                                ensuring you can focus on healing while enjoying the convenience of
                                your own space. Say goodbye to the hassle of clinic visits and hello
                                to rehabilitation in the comfort of your own home, where you can truly
                                unwind. Our holistic approach to care ensures convenience from consultation
                                to treatment to post-operative care</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cover">
                <div className="container">
                    <h2>Our Expertise Spans Across</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Orthopedic Care</h3>
                        <h3 className="grid_item">Geriatric Care</h3>
                        <h3 className="grid_item">Sports Injuries</h3>
                        <h3 className="grid_item">Neurologic Care</h3>
                        <h3 className="grid_item">Spinal Care</h3>
                        <h3 className="grid_item">Surgical Rehabilitation</h3>
                        <h3 className="grid_item">Chronic Pain Rehabilitation</h3>
                        <h3 className="grid_item">Ergonomic Therapy</h3>
                    </div>
                </div>
            </section>
            <section className="say">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Revitalize In Your Space</h2>
                            <h4>
                                Our expert therapists curate a personalized wellness journey tailored to your
                                unique needs and surroundings. Whether you're recovering from an injury, managing
                                chronic pain, or seeking to enhance your mobility, we're here to guide you
                                towards optimal health and vitality. We utilize a range of methods including
                                physical therapy, patient education, and specialized modalities to expedite
                                patient recovery.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/revitalize.webp')} className="img-fluid big_img" alt="say" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="sets_apart">
                <div className="container">
                    <h2>What Sets Us Apart</h2>
                    <h4>"With a wealth of expertise and experience in diverse physiotherapy
                        techniques and specialties, we help people recuperate and recover
                        from pain effectively."</h4>
                    <div className="grid_4">
                        <h3 className="grid_item">Proven Methodologies</h3>
                        <h3 className="grid_item">Individualized Care</h3>
                        <h3 className="grid_item">Patient-Centric Approach</h3>
                        <h3 className="grid_item">Internationally Accepted MOO</h3>
                    </div>
                </div>
            </section>
            <section className="backed">
                <div className="container">
                    <h2>Backed By Top Hospitals</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/zoi.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/renova.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/wellness.png')} className="img-fluid" />
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/apollo.png')} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Find Out Why We’re Buzzing</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { Physiotherapy }