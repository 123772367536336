import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const CareGiver = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Care Giver'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="heartfelt">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Heartfelt Care<br /> Everyday</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid mobile" src={require('./images/heartfelt_responsive.webp')} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/compassion.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>Compassion Never Goes Out Of Fashion</h2>
                            <h4>At Arush, we believe that compassion is the cornerstone of
                                quality care. Our dedicated caregivers embody this philosophy,
                                providing unparalleled support with empathy, understanding,
                                and genuine kindness. Just as fashion trends come and go, the
                                need for compassion remains constant, and our caregivers strive
                                to ensure that every individual we serve feels valued, respected, and cared for.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="explore">
                <img src={require('./images/explore_bg.webp')} className="img-fluid img_in_bg" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings3}>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Elderly Care</h3>
                                        <div>
                                            <h4>Companionship</h4>
                                            <h4>Leisure Activities</h4>
                                            <h4>Feeding</h4>
                                            <h4>Emotional Support</h4>
                                            <h4>Prevent Falls</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Personal Care</h3>
                                        <div>
                                            <h4>Bathing</h4>
                                            <h4>Dressing</h4>
                                            <h4>Grooming</h4>
                                            <h4>Feeding</h4>
                                            <h4>Toileting</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Medical Care</h3>
                                        <div>
                                            <h4>Assessment</h4>
                                            <h4>Monitoring Vitals</h4>
                                            <h4>Wound Care</h4>
                                            <h4>Medicine Administration</h4>
                                            <h4>Prescription Refills</h4>
                                            <h4>Mobility Assist</h4>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="col-md-4">
                            <h2>Explore Our Care</h2>
                            <div className="slider_buttons">
                                <button className="btn" onClick={previous}>
                                    <GoArrowDownLeft color="#307BC4" size={30} />
                                </button>
                                <button className="btn" onClick={next}>
                                    <GoArrowUpRight color="#307BC4" size={30} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="care_givers">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <h2>Caregivers Are The New Superheroes</h2>
                            <h4>Our team of caregivers embodies this spirit of heroism, providing compassionate care
                                to our clients with unwavering dedication and commitment. Just like superheroes, they
                                are there in times of crisis, offering strength, reassurance, and a helping hand. Whether
                                it's assisting with daily activities, providing medical support, or simply offering a listening ear, our caregivers are the unsung heroes of our community.</h4>
                        </div>
                        <div className="col-md-5">
                            <img src={require("./images/care_givers.webp")} className="img-fluid" alt="care_givers" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="medicines">
                        <h2>Caregivers You Can Count On</h2>
                        <div className="grid_4">
                            <h3 className="grid_item">Vast Expertise</h3>
                            <h3 className="grid_item">Background Check & Verification</h3>
                            <h3 className="grid_item">Individualized Care</h3>
                            <h3 className="grid_item">Continuity Of Care</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Where Words Fail, Care Speaks</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { CareGiver }