import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const BabyCareServices = () => {
    let sliderRef = useRef(null);
    const dispatch = useDispatch();

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const openModal = () => {
        dispatch(setModalTitle('Baby Care'));
        dispatch(handleShow());
    };

    return (
        <>
            <section className="cuddles">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Cuddles & Care<br /> For Your Precious Little One</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid mobile" src={require('./images/cuddles_mobile.webp')} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/where_love.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>Where Love Meets Little Feet</h2>
                            <h4>Bringing a new life into the world is indeed one of the most precious
                                and joyous experiences for any family. Our baby care services are
                                designed to make the early days of parenthood easier and more enjoyable
                                for you, providing reliable support and expert care for your newborn.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="making">
                <img src={require('./images/explore_bg.webp')} className="img-fluid img_in_bg" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings3}>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Newborn Nurturers</h3>
                                        <div>
                                            <h4>Grooming</h4>
                                            <h4>Bathing</h4>
                                            <h4>Toileting</h4>
                                            <h4>Cradling</h4>
                                            <h4>Feeding</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Sleep Specialists</h3>
                                        <div>
                                            <h4>Assess Newborn Sleep Needs</h4>
                                            <h4>Putting Baby To Sleep</h4>
                                            <h4>Baby Proofing</h4>
                                            <h4>Guidance on Night Time Nursing</h4>
                                            <h4>Nappy Change</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide_item_parent">
                                    <div className="slide_item">
                                        <h3>Daycare Assistants</h3>
                                        <div>
                                            <h4>Feeding</h4>
                                            <h4>Body Massage</h4>
                                            <h4>Grooming</h4>
                                            <h4>Nappy Change</h4>
                                            <h4>Safety & Comfort</h4>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="col-md-4">
                            <h2>Making Parenthood Easier</h2>
                            <div className="slider_buttons">
                                <button className="btn" onClick={previous}>
                                    <GoArrowDownLeft color="#307BC4" size={30} />
                                </button>
                                <button className="btn" onClick={next}>
                                    <GoArrowUpRight color="#307BC4" size={30} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cover">
                <div className="container">
                    <h2>Take A Break, We’ve Got This For You</h2>
                    <div className="grid_5">
                        <h3 className="grid_item">First Time Parents</h3>
                        <h3 className="grid_item">Single Parent</h3>
                        <h3 className="grid_item">Ones With Premature Borns</h3>
                        <h3 className="grid_item">Employed Parents</h3>
                        <h3 className="grid_item">C-Section Mothers</h3>
                    </div>
                </div>
            </section>
            <section className="say">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Helping You Juggle Life’s Many Joys </h2>
                            <h4>
                                At Arush, we understand that being a parent is a beautiful journey filled with countless
                                joys, but it can also be overwhelming at times. That's where we come in. Our baby caregiver
                                services are designed to provide you with the support and assistance you need to navigate
                                parenthood with ease.From expertly handling feeding and diapering to engaging your baby in
                                stimulating activities that promote growth and development, our caregivers are committed to
                                providing a nurturing and safe environment for your child. We understand that every family
                                is unique, so we tailor our services to meet your specific needs and preferences.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/helping.webp')} className="img-fluid big_img" alt="say" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="setting">
                <div className="container">
                    <h2>Setting Ourselves Apart</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/one_to_one.webp')} alt="one to one" className="img-fluid" />
                            <h4>One To One Care</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/certified.webp')} alt="one to one" className="img-fluid" />
                            <h4>Certified Team</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/constantly.webp')} alt="one to one" className="img-fluid" />
                            <h4>Constantly Trained</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/health&hygiene.webp')} alt="one to one" className="img-fluid" />
                            <h4>Health and Hygiene</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Hear From Happy Parents</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { BabyCareServices }