import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const MedicalEquipment = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Medical Equipment'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="from_our">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>From Our Door To Yours</h2>
                            <h2>We Deliver More Than Medical Equipment</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/from_our_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/no_hassle.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>No Hassle, Just Care</h2>
                            <h4>Say goodbye to long waits and cumbersome trips to the store.
                                With our convenient at- home medical equipment service, you
                                can get everything you need without leaving the comfort of
                                your home. Whether it's for recovery, daily assistance, or
                                improving your wellness routine, our hassle-free delivery ensures
                                you have the tools you need to thrive.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="medicines">
                <div className="container">
                    <h2>Empowering You With Options</h2>
                    <h3>Buy Or Rent Medical Equipment From Us</h3>
                    <div className="grid_4">
                        <h3 className="grid_item">Oxygen Concentrator</h3>
                        <h3 className="grid_item">Cardiac Monitors</h3>
                        <h3 className="grid_item">Wheel Chairs</h3>
                        <h3 className="grid_item">Motorized Bed</h3>
                        <h3 className="grid_item">Suction Apparatus</h3>
                        <h3 className="grid_item">Pulse Oximeter</h3>
                        <h3 className="grid_item">3M, N95 Masks</h3>
                        <h3 className="grid_item">Nebulizer</h3>
                    </div>
                    <div className="grid_3">
                        <h3 className="grid_item">BP Apparatus</h3>
                        <h3 className="grid_item">Infusion Pump</h3>
                        <h3 className="grid_item">Posture Corrector</h3>
                    </div>
                </div>
            </section>
            <section className="say">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Choose Excellence</h2>
                            <h3>Don’t Blend In</h3>
                            <h4>
                                Transform your home into a wellness sanctuary with the trendiest 
                                medical equipment, because health and style go hand in hand. 
                                Ordering medical equipment from us means choosing excellence in 
                                both products and services. With our extensive selection and 
                                knowledgeable staff, we're here to support you every step of the way.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/choose.webp')} className="img-fluid big_img" alt="choose" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="medicines">
                        <h2>Why Settle For Anything Less?</h2>
                        <div className="grid_4">
                            <h3 className="grid_item">Warranty Covered</h3>
                            <h3 className="grid_item">Free Installation</h3>
                            <h3 className="grid_item">Diverse Range</h3>
                            <h3 className="grid_item">Flexibility Meets Affordability</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>We'll Let Our Clients Do The Talking</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { MedicalEquipment }