import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import Slider from "react-slick";
import { settings, settings2, settings4 } from "./StyleGuide";
import { FaAnglesRight } from "react-icons/fa6";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const HomeHealthChecks = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Health Checks'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="wellness">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Wellness Starts At Home</h2>
                            <h2>With Our Home Health Checks</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/wellness_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/first_step.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>First Step To Healthier You</h2>
                            <h4>Don't let your health take a back seat – take control of your well-being
                                today with our home health checks. Book now and embark on the journey to
                                a healthier, happier you.Tailored to your individual needs, we make it
                                easy for health assessments to fit into your busy schedules</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cover">
                <div className="container">
                    <h2>How We Operate</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Book A Health Check</h3>
                        <h3 className="grid_item">Home Sample Collection</h3>
                        <h3 className="grid_item">Analysis</h3>
                        <h3 className="grid_item">Download Reports</h3>
                    </div>
                </div>
            </section>
            <section className="health_checks">
                <div className="container">
                    <h2>Health Checks Tailored To Assess Your Well-Being</h2>
                    <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings4}>
                        <div className="slide_item_parent">
                            <div className="slide_item">Cardiovascular</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Respiratory</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Liver</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Kidney</div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="say trans_hc">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Take A Step Closer To Wellness<br /> Without Stepping Out</h2>
                            <h4>
                                Don't let inconvenience be a barrier to your health. Take a step closer to
                                wellness without stepping out – schedule your home health check with us today
                                and prioritize your well-being from the comfort of your own home.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/take_a.webp')} className="img-fluid big_img" alt="say" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="premier">
                <div className="container">
                    <h2>Why We’re Premier Option</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Convenience</h3>
                        <h3 className="grid_item">Precision</h3>
                        <h3 className="grid_item">Sustainability</h3>
                        <h3 className="grid_item">Expediency</h3>
                    </div>
                </div>
            </section>
            <section className="why_we">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img className="img-fluid" src={require('./images/why_we.webp')} alt="why_we" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Why We’re The Right Fit</h2>
                            <ul>
                                <li><FaAnglesRight size={10} /> Qualified Phlebotomists</li>
                                <li><FaAnglesRight size={10} /> Latest Technologies</li>
                                <li><FaAnglesRight size={10} /> Expert Opinion</li>
                                <li><FaAnglesRight size={10} /> Tailored Recommendations</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Explore Why We're The Preferred Option</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { HomeHealthChecks }