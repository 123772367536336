import React from "react";
import Slider from "react-slick";
import { settings, settings2 } from "./StyleGuide";
import { Footer } from "../components/Footer";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';
const MedicineHomeDelivery = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Medicine Home Delivery'));
        dispatch(handleShow());
    };
    return (
        <div>
            <section className="stay">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Stay Stocked Up On Medicines Convenience In Every Dose</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/stay_mobile.webp')} className="img-fluid mobile" alt="stay" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ride">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img className="img-fluid big_img" src={require('./images/ride.webp')} alt="medicines" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Ride The Wave Of Convenience</h2>
                            <h4>
                                Get your medicine delivered straight to your door because health
                                shouldn't wait. With our home delivery service, you can upload
                                your prescriptions and well handle the rest. Whether you're
                                managing a chronic condition or just need a refill, we make it
                                easy to stay on top of your health. It's convenient, reliable,
                                and one less thing to worry about. Because when it comes to your
                                health, every moment counts.
                            </h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="medicines">
                <div className="container">
                    <h2>Medicines Delivered</h2>
                    <h3>Worries Relieved</h3>
                    <h3>
                        From prescriptions to over-the-counter remedies, we've got you
                        covered
                    </h3>
                    <div className="grid_5">
                        <h3 className="grid_item">Diabetes</h3>
                        <h3 className="grid_item">Cardiac Care</h3>
                        <h3 className="grid_item">Derma Care</h3>
                        <h3 className="grid_item">Eye Care</h3>
                        <h3 className="grid_item">Respiratory Care</h3>
                        <h3 className="grid_item">Kidney Care</h3>
                        <h3 className="grid_item">Liver Care</h3>
                        <h3 className="grid_item">Surgical</h3>
                        <h3 className="grid_item">Mom Baby Care</h3>
                        <h3 className="grid_item">Bone, Joint & Muscle Care</h3>
                    </div>
                </div>
            </section>
            <section className="say">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Say Goodbye To Pharmacy Queues</h2>
                            <h3>Hello To Hassle-Free Delivery</h3>
                            <h4>
                                With our service, you no longer need to waste time standing in
                                long pharmacy lines or battling traffic to pick up your
                                prescriptions. Instead, we bring your medications directly to
                                your doorstep, ensuring a seamless and stress-free experience
                                every time.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/say.webp')} className="img-fluid big_img" alt="say" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="feeling">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <h2>Feeling Unwell</h2>
                            <h3>But Don’t Have A Prescription</h3>
                            <h4>
                                Start A Consultation With Our Panel Of Doctors & Order Medicine
                                Through Valid Prescriptions
                            </h4>
                            <button className="btn_primary_gradient">Book Now</button>
                        </div>
                        <div className="col-md-5 right">
                            <img src={require('./images/feeling.webp')} className="img-fluid big_img" alt="feeling" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="medicines">
                        <h2>Why We’re The Right Fit</h2>
                        <div className="grid_4">
                            <h3 className="grid_item">Comprehensive Range</h3>
                            <h3 className="grid_item">Stringent Quality Checks</h3>
                            <h3 className="grid_item">Barcoded Medicines</h3>
                            <h3 className="grid_item">Reliability</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Don’t Just Take Our Words For It</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>My mother received exceptional care right in the comfort
                                    of our home. The nurseswere professional, compassionate,
                                    and went above and beyond  ensure her well-being. They provided
                                    personalized care tailored to her needs,making a difficult time much more .....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export { MedicineHomeDelivery }