import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoIosCloseCircleOutline, IoIosCheckmarkCircleOutline } from "react-icons/io";
import "../Navbar/Navbar.css";
import { GrEdit } from "react-icons/gr";
import { FaAnglesRight } from "react-icons/fa6";
import { useAuth } from '../../Utils/AuthContext';
import axiosConfig from "../../Service/axiosConfig";
import { useSelector, useDispatch } from 'react-redux';
import { handleClose, handleShow } from '../../redux/Slices/ModalSlice';
import { useNavigate } from 'react-router-dom';
import '../components.css';

const Booking = () => {
    const [formData, setFormData] = useState({});
    const [timer, setTimer] = useState(30);
    const [stage, setStage] = useState('mobile');
    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const show = useSelector((state) => state.modal.show);
    const modalTitle = useSelector((state) => state.modal.modalTitle);
    const [bookings, setBookings] = useState([]);
    const [bookingId, setBookingId] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handlePatientDetails = () => {
        navigate('/patient-dashboard/bookings');
        dispatch(handleClose());
    }

    const handleResend = async () => {
        try {
            await axiosConfig.post(`accounts/validate_mobile/`, {
                mobileno: formData.mobile
            });
            setTimer(30);
        } catch (error) {
            console.log("Error resending : ", error);
        }
    }

    const onHide = () => {
        setError('');
        setFormData(() => ({
            ...formData,
            otp: ''
        }))
        dispatch(handleClose());
        if (stage === 'success') {
            handleNavigate();
        }
    }

    const handleMobileEdit = () => {
        setStage('mobile');
        setError('');
        setFormData({
            ...formData,
            otp: ''
        })
    }

    const handleNavigate = () => {
        switch (modalTitle) {
            case 'Home Nurse':
                navigate('/home-nurse');
                break;
            case 'Care Giver':
                navigate('/care-giver');
                break;
            case 'Baby Care':
                navigate('/baby-care-services');
                break;
            case 'Home ICU Setup':
                navigate('/home-icu-setup');
                break;
            case 'Medical Equipment':
                navigate('/medical-equipment');
                break;
            case 'Doctor Consultation':
                navigate('/doctor-consultation');
                break;
            case 'Physiotherapy':
                navigate('/physiotherapy');
                break;
            case 'Medicine Home Delivery':
                navigate('/medicine-home-delivery');
                break;
            case 'Health Checks':
                navigate('/home-health-checks');
                break;
            case 'Diagnostics':
                navigate('/diagnostics');
                break;
            case 'Radiology':
                navigate('/radiology');
                break;
            default:
                break;
        }
    }

    const BookService = async () => {
        try {
            if (localStorage.getItem('first_name')) {
                let category = categories.find((category) => category.name === modalTitle);
                const response = await axiosConfig.post(`/accounts/leads/`, {
                    "user": localStorage.getItem('user_id'),
                    "mobileno": formData.mobile,
                    "category": category?.id,
                    "status": "Processing",
                    "state": 2,
                    "city": 5,
                })
                setBookingId(response.data.id);
                setStage('success');
                setError('');
            } else {
                setStage('registration');
                setError('');
            }
        } catch (error) {
            console.log("Error booking service : ", error);
        }
    }

    const GetCategories = async () => {
        try {
            const response = await axiosConfig.get(`/catlog/categories/`);
            setCategories(response.data.results);

        } catch (error) {
            console.log(error);
        }
    }

    const GetBookingDetails = async () => {
        try {
            if (isLoggedIn) {
                let category = categories.find((item) => item.name === modalTitle);
                const response = await axiosConfig.get(`/accounts/leads/?user=${localStorage.getItem('user_id')}&category=${category?.id}`);
                setBookings(response.data.results);
                if (response.data.results.length > 0 && localStorage.getItem('first_name')) {
                    setStage('booking');
                } else {
                    setStage('prebooking');
                }
            } else {
                setStage('mobile')
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBooking = async (e) => {
        e.preventDefault();
        try {
            if (stage === 'mobile') {
                await axiosConfig.post(`accounts/validate_mobile/`, { mobileno: formData.mobile });
                localStorage.setItem('mobileno', formData.mobile);
                setStage('otp');
                setError('');
                setTimer(30);
            } else if (stage === 'otp') {
                const response = await axiosConfig.post(`accounts/verify_otp/`, {
                    mobileno: formData.mobile,
                    otp: formData.otp
                });
                if (response.data) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user_id', response.data.user_id);
                    setFormData({
                        ...formData,
                        user_id: localStorage.getItem('user_id'),
                        mobile: localStorage.getItem('mobileno'),
                        otp: ''
                    });
                    setError('');
                    if (response.data.user_first_name) {
                        localStorage.setItem('email', response.data.user_email);
                        localStorage.setItem('first_name', response.data.user_first_name);
                        localStorage.setItem('last_name', response.data.user_last_name);
                        modalTitle === 'Login' ? dispatch(handleClose()) : setStage('prebooking');
                    } else {
                        setStage('registration');
                        setError('');
                    }
                    setIsLoggedIn(true);
                }

            } else if (stage === 'registration') {
                await axiosConfig.patch(`accounts/profile_update/`, formData);
                localStorage.setItem('first_name', formData.first_name);
                localStorage.setItem('last_name', formData.last_name);
                localStorage.setItem('email', formData.email);
                modalTitle === 'Login' ? dispatch(handleClose()) : setStage('prebooking');
            } else if (stage === 'prebooking') {
                BookService();
            } else if (stage === 'booking') {
                setStage('prebooking');
            }
        } catch (error) {
            console.log("Error", error);
            if (error.response.data.error) {
                setError(error.response.data.error);
            } else if (error.response.data.message) {
                stage === "otp" ? setError("Invalid otp") : setError(error.response.data.message);
            }
        }
    };

    useEffect(() => {
        let countdown;
        if (stage === 'otp' && timer > 0) {
            countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [stage, timer]);

    useEffect(() => {
        if (isLoggedIn) {
            setFormData({
                mobile: localStorage.getItem('mobileno') || '',
                otp: '',
                first_name: localStorage.getItem('first_name') || '',
                last_name: localStorage.getItem('last_name') || '',
                email: localStorage.getItem('email') || '',
                user_id: localStorage.getItem('user_id') || '',
                family_champion: localStorage.getItem('family_champion') || '',
            });
        }
        if (categories.length === 0) {
            GetCategories();
        }
        if (show) {
            GetBookingDetails();
        }
    }, [show, isLoggedIn]);
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <div className={`d-flex align-items-center ${stage === 'prebooking' ? 'ms-auto' : ''}`}>
                        {stage === 'prebooking' && <h4 className='mb-0 me-2'>Booking For</h4>}
                        <h3>{modalTitle}</h3>
                    </div>
                    <IoIosCloseCircleOutline size={18} onClick={onHide} className="close-icon" />
                </Modal.Header>
                <Modal.Body>
                    <div className='booking-modal-body'>
                        {error && <h4 className='error'>{error}</h4>}
                        {stage !== 'booking' && stage !== 'success' &&
                            <h4 className='booking-step'>
                                {
                                    stage === 'mobile' ? 'Login' : stage === 'otp' ? 'Verify OTP' : stage === 'registration' ? 'Register Now' : stage === 'prebooking' ? 'Welcome' : ''
                                }
                            </h4>
                        }
                        <form onSubmit={handleBooking}>
                            {stage === 'mobile' && (
                                <div className='w_300'>
                                    <label htmlFor="mobile" className="form-label">Mobile Number</label>
                                    <div className="form-control mobileno">
                                        <h4>+91</h4>
                                        <input
                                            type="text"
                                            placeholder="XXXXXXXXXX"
                                            name="mobile"
                                            maxLength={10}
                                            minLength={10}
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            )}
                            {stage === 'otp' && (
                                <div className='w_300'>
                                    <label className="form-label mobile-label">Mobile Number</label>
                                    <div className='mobile-underline'>
                                        <h4>{formData.mobile}</h4>
                                        <GrEdit size={10} onClick={() => handleMobileEdit()} />
                                    </div>
                                    <label htmlFor="otp" className="form-label">Enter OTP</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="xxxx"
                                        name="otp"
                                        maxLength={4}
                                        minLength={4}
                                        value={formData.otp}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            {stage === 'registration' && (
                                <div className='w_300'>
                                    <div className='mobile-underline' style={{ textDecoration: 'none' }}>
                                        <h4>+91 {localStorage.getItem('mobileno')}</h4>
                                        <IoIosCheckmarkCircleOutline size={16} color='#31C07E' />
                                    </div>
                                    <label htmlFor="first_name" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {stage === 'prebooking' && (
                                <div className='w_300'>
                                    <div className='mobile-underline' style={{ textDecoration: 'none' }}>
                                        <h4>+91 {localStorage.getItem('mobileno')}</h4>
                                        <IoIosCheckmarkCircleOutline size={16} color='#31C07E' />
                                    </div>
                                    <label htmlFor="first_name" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="family-champion" className="form-label">Family Champion</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Family Champion"
                                        name="family_champion"
                                        value={formData.family_champion}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {stage === 'booking' &&
                                <div>
                                    {bookings.length > 0 &&
                                        <>
                                            <h4><span className='wt_500'>Last booked on:</span> <span>{bookings[0].created_date}</span></h4>
                                            <h4>#{bookings[0].id}</h4>
                                        </>
                                    }
                                    <h4 className='further'>Waiting for executive to contact meantime help us provide below details</h4>
                                    <div className='further-actions-container'>
                                        <h4 className='further-actions' onClick={handlePatientDetails}><FaAnglesRight size={10} /><span>Patient Demographics</span></h4>
                                        <h4 className='further-actions' onClick={handlePatientDetails}><FaAnglesRight size={10} /><span>Upload Prescription</span></h4>
                                    </div>
                                </div>
                            }
                            {stage !== 'success' &&
                                <button type="submit" className="btn_primary_gradient">
                                    {stage === 'booking' ? "Book Again" : stage === 'otp' ? "Verify OTP" : stage === 'prebooking' ? 'Book now' : 'Submit'}
                                </button>
                            }
                            {stage === 'otp' &&
                                <h4 className={`${timer > 0 ? 'pointer-disable' : ' '} resend`}>
                                    <span className={`${timer > 0 ? '' : 'underline-pointer'}`} onClick={handleResend}>
                                        Resend {timer > 0 ? ` in ${timer} seconds` : ` OTP`}
                                    </span>
                                </h4>
                            }
                        </form>
                        {stage === 'prebooking' &&
                            <div className="w_300 mx-auto">
                                <div className="d-flex justify-content-center">
                                    <input type='checkbox' className='mb-0'/>
                                    <p className='mb-0 ms-2'>By continuing, you agree to our</p>
                                </div>
                                <div className="policies">
                                    <a href="#"><p>Terms & Conditions</p></a>
                                    <a href="#"><p>Privacy Policy</p></a>
                                </div>
                            </div> 
                        }
                        {stage === 'success' &&
                            <div>
                                <div className='text-center'>
                                    {/* <IoIosCheckmarkCircleOutline size={68} color='#31C07E' /> */}
                                    <img src={require('../../pages/images/success.gif')} className='img-fluid' alt="success" style={{ width: '200px' }} />
                                    <h4 className='success-heading'>Booking Successful </h4>
                                    <h4 className='booking-id'>Your Booking ID: {bookingId}</h4>
                                    <h4>Our executive will contact you shortly</h4>
                                </div>
                                <h4 className='further'>
                                    In meantime, please provide the details below to help us<br />
                                    better understand your exact requirements
                                </h4>
                                <div className='further-actions-container'>
                                    <h4 className='further-actions' onClick={handlePatientDetails}><FaAnglesRight size={10} /><span>Patient Demographics</span></h4>
                                    <h4 className='further-actions' onClick={handlePatientDetails}><FaAnglesRight size={10} /><span>Upload Prescription</span></h4>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}

export { Booking }