import React, { useState, useEffect } from "react";
import { NotFound } from "../../pages";
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
import { MdOutlineEdit } from "react-icons/md"
import { ReactQuillComponent } from "./ReactQuill/ReactQuill";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdInfo } from 'react-icons/md';
import { FaFilePdf } from 'react-icons/fa';
import { FaFileExcel, FaFileWord, FaFileCsv } from 'react-icons/fa';
import { IoMdDownload } from "react-icons/io";
const FormParser = ({ modelObject, formData, formSubmit, error, button, buttonTitle, readOnly, LoadingButton }) => {
    const [Data, SetData] = useState({})
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [dynamicOptions, SetDynamicOptions] = useState({})
    const [DataFilter, SetDataFilter] = useState([])
    const [Option, setOption] = useState({});
    const [Error, SetError] = useState("")
    const [Nextbutton, setNextbutton] = useState("")
    const [FieldErrors, setFieldErrors] = useState({});
    const [nextPageUrl, setNextPageUrl] = useState('');
    const [search, setSearch] = useState('');
    const GetData = async () => {
        try {
            for (const field_obj of field_objects) {
                // Check if the options_url contains a question mark

                if (field_obj.fieldType === "dynamicdropdown"||field_obj.fieldType==="Phone"||field_obj.fieldType==="Doctor") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        console.log(`API request for ${field_obj.fieldName} includes a filter.`);
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
                if (field_obj.fieldType === "HandleInfinitiSelect") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        console.log(`API request for ${field_obj.fieldName} includes a filter.`);
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}&search=${search}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        if (formData) {
                            setSearch(formData.city_name)
                        }
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}&search=${search}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleScroll = async (fieldName, fieldType) => {
        if (!loading && nextPageUrl) {
            setLoading1(true);
            try {
                const product = await axiosConfig.get(
                    nextPageUrl[fieldName].replace(/^http:/, 'https:')
                );
                const product_data = product.data.results;

                setNextPageUrl(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: product.data.next
                }));
                if (fieldType === "dynamicSelectdropdown") {
                    SetSelectDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [fieldName]: [
                            ...(prevOptions[fieldName] || []), // Preserve existing data
                            ...product_data // Append new data
                        ]
                    }));
                }
                SetDynamicOptions(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: [
                        ...(prevOptions[fieldName] || []), // Preserve existing data
                        ...product_data // Append new data
                    ]
                }));
            } catch (error) {
                console.error(`Error fetching data for ${fieldName}:`, error);
            }
            try {

            } finally {
                setLoading1(false);
            }
        }
    };

    console.log(nextPageUrl)
    const handleSearch = (value) => {
        setSearch(value)
    }
    const GetMultiData = async () => {
        let data = []
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        console.log(`API request for ${field_obj.fieldName} includes a filter.`, formData);
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        console.log("fomdata", 1)
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    const HandleRadioChange = (e, ItemName) => {
        const { name } = e.target
        SetData({ ...Data, [name]: ItemName });
    }
    const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([])
    const SelectOptions = (id, name) => {
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                if (item.renderField === name) {
                    console.log(id, `${item.options_url}${id}`)
                    let url = `${item.options_url}${id}&is_suspended=${formData ? "" : false}`
                    axiosConfig.get(url).then(res => {
                        console.log(res.data.results)
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [item.fieldName]: res.data.next
                        }));
                        SetSelectDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [item.fieldName]: res.data.results
                        }));
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }
        })
    }
    const editDataRender = async (formData) => {
        field_objects.forEach(async (field_obj, idx) => {
            if (formData) {
                Data[field_obj.fieldName] = formData[field_obj.fieldName]
                SetData(formData)
                if (field_obj.fieldType === "dynamicSelectdropdown") {
                    if (formData[field_obj.renderField]) {
                        let url = `${field_obj.options_url}${formData[field_obj.renderField]}`
                        console.log(url)
                        await axiosConfig.get(url).then(res => {
                            SetSelectDynamicOptions(prevOptions => ({
                                ...prevOptions,
                                [field_obj.fieldName]: res.data.results
                            }));
                            const find = res.data.results.find(find => find.id === formData[field_obj.fieldName])
                            setOption({ value: find.id, label: find[field_obj.displayField] })
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    let data = []
                    formData && formData[field_obj.fieldName] && formData[field_obj.fieldName].forEach(value => {
                        console.log(value, dynamicOptions)
                        const findData = dynamicOptions[field_obj.fieldName].find(find => find.id === value)
                        data.push(findData)
                    })
                    setSelectedOptions(data)
                }
            }
        })
    }
    const [selectedOptions, setSelectedOptions] = useState([])
    console.log(selectedOptions)
    useEffect(() => {
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 1000)
        GetData();
        GetMultiData()
    }, [search])
    useEffect(() => {
        if (formData) {
            editDataRender(formData)
        }
    }, [formData])
    console.log(modelObject)
    const [FilterData, SetFilterdata] = useState([])
    const [Name, SetName] = useState("")
    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        SetData({ ...Data, [name]: value })
        validateField(name, value)
        SetName(value)
    }
    const Handlecheckboxchange = (e) => {
        console.log(e.target.checked)
        const name = e.target.name;
        const value = e.target.checked

        SetData({ ...Data, [name]: value })
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        SetData({ ...Data, [name]: selectedOption.value });
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                if (item.renderField === name) {
                    SelectOptions(selectedOption.value, name)
                }
            }
        })
    };
    const handleSelect = (selectedOption, name) => {
        console.log(selectedOption, name)
        SetData({ ...Data, ["country_calling_code"]: selectedOption.value })
    };
    const handleSelectDoctor = (selectedOption, name) => {
        console.log(selectedOption, name)
        SetData({ ...Data, ["title"]: selectedOption.value })
    };
    const handleMultiSelectChange = (selectedOption, Value, name) => {
        let data = []
        selectedOption.forEach(res => {
            data.push(res.value)
        })
        console.log(data)
        SetData({ ...Data, [name]: data });
    };
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const HandleMultiPulFiles = (e) => {
        e.preventDefault()
        const file = e.target.files;
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const OnSubmit = (e) => {
        e.preventDefault();
        const requiredFieldErrors = validateRequiredFields();

        if (requiredFieldErrors) {
            console.log(requiredFieldErrors)
            setFieldErrors(requiredFieldErrors);
            return;
        }

        // Reset field-level errors
        setFieldErrors({});
        formSubmit(Data, setFieldErrors);
    }

    console.log(Data)
    const validateField = (name, value) => {
        const regex = {
            country_name: /^[a-zA-Z]+$/,
            state_name: /^[a-zA-Z]+$/,
            city_name: /^[a-zA-Z]+$/,
            pincode: /^[0-9]+$/,
            email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            cpt_code: /^[0-9]+$/,
        };

        const fields = field_objects.find(fields => fields.fieldName === name);

        if (value.trim() === '') {
            setFieldErrors({});
        } else {
            const isInputValid = regex[name] ? regex[name].test(value) : true;

            if (!isInputValid) {
                let name = fields.fieldName;
                setFieldErrors({ [name]: `Enter a valid ${fields.label}` });
            } else {
                setFieldErrors({});
            }
        }
    };

    let field_objects = []
    modelObject.orderBy.forEach((order_obj, idx) => {
        modelObject.fields.forEach((field_obj, index) => {
            if (field_obj.fieldName === order_obj) {
                let flagAddClass = true
                modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
                    if (classObj[field_obj.fieldName]) {
                        field_obj['groupClass'] = classObj[field_obj.fieldName]
                        flagAddClass = false
                    }
                })
                if (modelObject.layoutClass && flagAddClass) {
                    field_obj['groupClass'] = modelObject.layoutClass
                }
                field_objects.push(field_obj)
            }
        })
    })
    const validateRequiredFields = () => {
        const requiredFieldErrors = {};

        field_objects.forEach((fieldItem) => {
            if (fieldItem.required && !Data[fieldItem.fieldName]) {
                requiredFieldErrors[fieldItem.fieldName] = `${fieldItem.label} is required`;
            }
        });

        return Object.keys(requiredFieldErrors).length > 0 ? requiredFieldErrors : null;
    };
    function getFileIcon(documentUrl) {
        if (documentUrl.type) {
            if (documentUrl.type === 'application/pdf') {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                documentUrl.type === 'application/vnd.ms-excel') {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                documentUrl === 'application/msword') {
                return <FaFileWord size={20} />;
            } else if (documentUrl.type === 'text/csv') {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.type === "application/x-msdownload") {
                return <IoMdDownload size={20} />
            }
            else {
                // Return a default icon or null if no matching file type
                return (
                    <img
                        src={URL.createObjectURL(documentUrl)}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
        else {
            if (documentUrl.endsWith(".pdf")) {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.endsWith(".xlsx") || documentUrl.endsWith(".xls")) {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.endsWith(".docx") || documentUrl.endsWith(".doc")) {
                return <FaFileWord size={20} />;
            } else if (documentUrl.endsWith(".csv")) {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.endsWith(".exe")) {
                return <IoMdDownload size={20} />;
            }
            else {
                return (
                    <img
                        src={documentUrl}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
    }
    const countries = [
        { code: 'AF', name: 'Afghanistan', flag: 'https://www.countryflags.io/AF/flat/64.png', mobileCode: '+93' },
        { code: 'AX', name: 'Åland Islands', flag: 'https://www.countryflags.io/AX/flat/64.png', mobileCode: '+358' },
        { code: 'AL', name: 'Albania', flag: 'https://www.countryflags.io/AL/flat/64.png', mobileCode: '+355' },
        { code: 'DZ', name: 'Algeria', flag: 'https://www.countryflags.io/DZ/flat/64.png', mobileCode: '+213' },
        { code: 'AS', name: 'American Samoa', flag: 'https://www.countryflags.io/AS/flat/64.png', mobileCode: '+1' },
        { code: 'AD', name: 'Andorra', flag: 'https://www.countryflags.io/AD/flat/64.png', mobileCode: '+376' },
        { code: 'AO', name: 'Angola', flag: 'https://www.countryflags.io/AO/flat/64.png', mobileCode: '+244' },
        { code: 'AI', name: 'Anguilla', flag: 'https://www.countryflags.io/AI/flat/64.png', mobileCode: '+1' },
        { code: 'AQ', name: 'Antarctica', flag: 'https://www.countryflags.io/AQ/flat/64.png', mobileCode: null },
        { code: 'AG', name: 'Antigua and Barbuda', flag: 'https://www.countryflags.io/AG/flat/64.png', mobileCode: '+1' },
        { code: 'AR', name: 'Argentina', flag: 'https://www.countryflags.io/AR/flat/64.png', mobileCode: '+54' },
        { code: 'AM', name: 'Armenia', flag: 'https://www.countryflags.io/AM/flat/64.png', mobileCode: '+374' },
        { code: 'AW', name: 'Aruba', flag: 'https://www.countryflags.io/AW/flat/64.png', mobileCode: '+297' },
        { code: 'AU', name: 'Australia', flag: 'https://www.countryflags.io/AU/flat/64.png', mobileCode: '+61' },
        { code: 'AT', name: 'Austria', flag: 'https://www.countryflags.io/AT/flat/64.png', mobileCode: '+43' },
        { code: 'AZ', name: 'Azerbaijan', flag: 'https://www.countryflags.io/AZ/flat/64.png', mobileCode: '+994' },
        { code: 'BS', name: 'Bahamas', flag: 'https://www.countryflags.io/BS/flat/64.png', mobileCode: '+1' },
        { code: 'BH', name: 'Bahrain', flag: 'https://www.countryflags.io/BH/flat/64.png', mobileCode: '+973' },
        { code: 'BD', name: 'Bangladesh', flag: 'https://www.countryflags.io/BD/flat/64.png', mobileCode: '+880' },
        { code: 'BB', name: 'Barbados', flag: 'https://www.countryflags.io/BB/flat/64.png', mobileCode: '+1' },
        { code: 'BY', name: 'Belarus', flag: 'https://www.countryflags.io/BY/flat/64.png', mobileCode: '+375' },
        { code: 'BE', name: 'Belgium', flag: 'https://www.countryflags.io/BE/flat/64.png', mobileCode: '+32' },
        { code: 'BZ', name: 'Belize', flag: 'https://www.countryflags.io/BZ/flat/64.png', mobileCode: '+501' },
        { code: 'BJ', name: 'Benin', flag: 'https://www.countryflags.io/BJ/flat/64.png', mobileCode: '+229' },
        { code: 'BM', name: 'Bermuda', flag: 'https://www.countryflags.io/BM/flat/64.png', mobileCode: '+1' },
        { code: 'BT', name: 'Bhutan', flag: 'https://www.countryflags.io/BT/flat/64.png', mobileCode: '+975' },
        { code: 'BO', name: 'Bolivia (Plurinational State of)', flag: 'https://www.countryflags.io/BO/flat/64.png', mobileCode: '+591' },
        { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', flag: 'https://www.countryflags.io/BQ/flat/64.png', mobileCode: '+599' },
        { code: 'BA', name: 'Bosnia and Herzegovina', flag: 'https://www.countryflags.io/BA/flat/64.png', mobileCode: '+387' },
        { code: 'BW', name: 'Botswana', flag: 'https://www.countryflags.io/BW/flat/64.png', mobileCode: '+267' },
        { code: 'BV', name: 'Bouvet Island', flag: 'https://www.countryflags.io/BV/flat/64.png', mobileCode: null },
        { code: 'BR', name: 'Brazil', flag: 'https://www.countryflags.io/BR/flat/64.png', mobileCode: '+55' },
        { code: 'IO', name: 'British Indian Ocean Territory', flag: 'https://www.countryflags.io/IO/flat/64.png', mobileCode: '+246' },
        { code: 'BN', name: 'Brunei Darussalam', flag: 'https://www.countryflags.io/BN/flat/64.png', mobileCode: '+673' },
        { code: 'BG', name: 'Bulgaria', flag: 'https://www.countryflags.io/BG/flat/64.png', mobileCode: '+359' },
        { code: 'BF', name: 'Burkina Faso', flag: 'https://www.countryflags.io/BF/flat/64.png', mobileCode: '+226' },
        { code: 'BI', name: 'Burundi', flag: 'https://www.countryflags.io/BI/flat/64.png', mobileCode: '+257' },
        { code: 'CV', name: 'Cabo Verde', flag: 'https://www.countryflags.io/CV/flat/64.png', mobileCode: '+238' },
        { code: 'KH', name: 'Cambodia', flag: 'https://www.countryflags.io/KH/flat/64.png', mobileCode: '+855' },
        { code: 'CM', name: 'Cameroon', flag: 'https://www.countryflags.io/CM/flat/64.png', mobileCode: '+237' },
        { code: 'CA', name: 'Canada', flag: 'https://www.countryflags.io/CA/flat/64.png', mobileCode: '+1' },
        { code: 'KY', name: 'Cayman Islands', flag: 'https://www.countryflags.io/KY/flat/64.png', mobileCode: '+1' },
        { code: 'CF', name: 'Central African Republic', flag: 'https://www.countryflags.io/CF/flat/64.png', mobileCode: '+236' },
        { code: 'TD', name: 'Chad', flag: 'https://www.countryflags.io/TD/flat/64.png', mobileCode: '+235' },
        { code: 'CL', name: 'Chile', flag: 'https://www.countryflags.io/CL/flat/64.png', mobileCode: '+56' },
        { code: 'CN', name: 'China', flag: 'https://www.countryflags.io/CN/flat/64.png', mobileCode: '+86' },
        { code: 'CX', name: 'Christmas Island', flag: 'https://www.countryflags.io/CX/flat/64.png', mobileCode: '+61' },
        { code: 'CC', name: 'Cocos (Keeling) Islands', flag: 'https://www.countryflags.io/CC/flat/64.png', mobileCode: '+61' },
        { code: 'CO', name: 'Colombia', flag: 'https://www.countryflags.io/CO/flat/64.png', mobileCode: '+57' },
        { code: 'KM', name: 'Comoros', flag: 'https://www.countryflags.io/KM/flat/64.png', mobileCode: '+269' },
        { code: 'CG', name: 'Congo', flag: 'https://www.countryflags.io/CG/flat/64.png', mobileCode: '+242' },
        { code: 'CD', name: 'Congo (Democratic Republic of the)', flag: 'https://www.countryflags.io/CD/flat/64.png', mobileCode: '+243' },
        { code: 'CK', name: 'Cook Islands', flag: 'https://www.countryflags.io/CK/flat/64.png', mobileCode: '+682' },
        { code: 'CR', name: 'Costa Rica', flag: 'https://www.countryflags.io/CR/flat/64.png', mobileCode: '+506' },
        { code: 'HR', name: 'Croatia', flag: 'https://www.countryflags.io/HR/flat/64.png', mobileCode: '+385' },
        { code: 'CU', name: 'Cuba', flag: 'https://www.countryflags.io/CU/flat/64.png', mobileCode: '+53' },
        { code: 'CW', name: 'Curaçao', flag: 'https://www.countryflags.io/CW/flat/64.png', mobileCode: '+599' },
        { code: 'CY', name: 'Cyprus', flag: 'https://www.countryflags.io/CY/flat/64.png', mobileCode: '+357' },
        { code: 'CZ', name: 'Czech Republic', flag: 'https://www.countryflags.io/CZ/flat/64.png', mobileCode: '+420' },
        { code: 'DK', name: 'Denmark', flag: 'https://www.countryflags.io/DK/flat/64.png', mobileCode: '+45' },
        { code: 'DJ', name: 'Djibouti', flag: 'https://www.countryflags.io/DJ/flat/64.png', mobileCode: '+253' },
        { code: 'DM', name: 'Dominica', flag: 'https://www.countryflags.io/DM/flat/64.png', mobileCode: '+1' },
        { code: 'DO', name: 'Dominican Republic', flag: 'https://www.countryflags.io/DO/flat/64.png', mobileCode: '+1' },
        { code: 'EC', name: 'Ecuador', flag: 'https://www.countryflags.io/EC/flat/64.png', mobileCode: '+593' },
        { code: 'EG', name: 'Egypt', flag: 'https://www.countryflags.io/EG/flat/64.png', mobileCode: '+20' },
        { code: 'SV', name: 'El Salvador', flag: 'https://www.countryflags.io/SV/flat/64.png', mobileCode: '+503' },
        { code: 'GQ', name: 'Equatorial Guinea', flag: 'https://www.countryflags.io/GQ/flat/64.png', mobileCode: '+240' },
        { code: 'ER', name: 'Eritrea', flag: 'https://www.countryflags.io/ER/flat/64.png', mobileCode: '+291' },
        { code: 'EE', name: 'Estonia', flag: 'https://www.countryflags.io/EE/flat/64.png', mobileCode: '+372' },
        { code: 'ET', name: 'Ethiopia', flag: 'https://www.countryflags.io/ET/flat/64.png', mobileCode: '+251' },
        { code: 'FK', name: 'Falkland Islands (Malvinas)', flag: 'https://www.countryflags.io/FK/flat/64.png', mobileCode: '+500' },
        { code: 'FO', name: 'Faroe Islands', flag: 'https://www.countryflags.io/FO/flat/64.png', mobileCode: '+298' },
        { code: 'FJ', name: 'Fiji', flag: 'https://www.countryflags.io/FJ/flat/64.png', mobileCode: '+679' },
        { code: 'FI', name: 'Finland', flag: 'https://www.countryflags.io/FI/flat/64.png', mobileCode: '+358' },
        { code: 'FR', name: 'France', flag: 'https://www.countryflags.io/FR/flat/64.png', mobileCode: '+33' },
        { code: 'GF', name: 'French Guiana', flag: 'https://www.countryflags.io/GF/flat/64.png', mobileCode: '+594' },
        { code: 'PF', name: 'French Polynesia', flag: 'https://www.countryflags.io/PF/flat/64.png', mobileCode: '+689' },
        { code: 'TF', name: 'French Southern Territories', flag: 'https://www.countryflags.io/TF/flat/64.png', mobileCode: null },
        { code: 'GA', name: 'Gabon', flag: 'https://www.countryflags.io/GA/flat/64.png', mobileCode: '+241' },
        { code: 'GM', name: 'Gambia', flag: 'https://www.countryflags.io/GM/flat/64.png', mobileCode: '+220' },
        { code: 'GE', name: 'Georgia', flag: 'https://www.countryflags.io/GE/flat/64.png', mobileCode: '+995' },
        { code: 'DE', name: 'Germany', flag: 'https://www.countryflags.io/DE/flat/64.png', mobileCode: '+49' },
        { code: 'GH', name: 'Ghana', flag: 'https://www.countryflags.io/GH/flat/64.png', mobileCode: '+233' },
        { code: 'GI', name: 'Gibraltar', flag: 'https://www.countryflags.io/GI/flat/64.png', mobileCode: '+350' },
        { code: 'GR', name: 'Greece', flag: 'https://www.countryflags.io/GR/flat/64.png', mobileCode: '+30' },
        { code: 'GL', name: 'Greenland', flag: 'https://www.countryflags.io/GL/flat/64.png', mobileCode: '+299' },
        { code: 'GD', name: 'Grenada', flag: 'https://www.countryflags.io/GD/flat/64.png', mobileCode: '+1' },
        { code: 'GP', name: 'Guadeloupe', flag: 'https://www.countryflags.io/GP/flat/64.png', mobileCode: '+590' },
        { code: 'GU', name: 'Guam', flag: 'https://www.countryflags.io/GU/flat/64.png', mobileCode: '+1' },
        { code: 'GT', name: 'Guatemala', flag: 'https://www.countryflags.io/GT/flat/64.png', mobileCode: '+502' },
        { code: 'GG', name: 'Guernsey', flag: 'https://www.countryflags.io/GG/flat/64.png', mobileCode: '+44' },
        { code: 'GN', name: 'Guinea', flag: 'https://www.countryflags.io/GN/flat/64.png', mobileCode: '+224' },
        { code: 'GW', name: 'Guinea-Bissau', flag: 'https://www.countryflags.io/GW/flat/64.png', mobileCode: '+245' },
        { code: 'GY', name: 'Guyana', flag: 'https://www.countryflags.io/GY/flat/64.png', mobileCode: '+592' },
        { code: 'HT', name: 'Haiti', flag: 'https://www.countryflags.io/HT/flat/64.png', mobileCode: '+509' },
        { code: 'HM', name: 'Heard Island and McDonald Islands', flag: 'https://www.countryflags.io/HM/flat/64.png', mobileCode: null },
        { code: 'VA', name: 'Holy See', flag: 'https://www.countryflags.io/VA/flat/64.png', mobileCode: '+39' },
        { code: 'HN', name: 'Honduras', flag: 'https://www.countryflags.io/HN/flat/64.png', mobileCode: '+504' },
        { code: 'HK', name: 'Hong Kong', flag: 'https://www.countryflags.io/HK/flat/64.png', mobileCode: '+852' },
        { code: 'HU', name: 'Hungary', flag: 'https://www.countryflags.io/HU/flat/64.png', mobileCode: '+36' },
        { code: 'IS', name: 'Iceland', flag: 'https://www.countryflags.io/IS/flat/64.png', mobileCode: '+354' },
        { code: 'IN', name: 'India', flag: 'https://www.countryflags.io/IN/flat/64.png', mobileCode: '+91' },
        { code: 'ID', name: 'Indonesia', flag: 'https://www.countryflags.io/ID/flat/64.png', mobileCode: '+62' },
        { code: 'IR', name: 'Iran (Islamic Republic of)', flag: 'https://www.countryflags.io/IR/flat/64.png', mobileCode: '+98' },
        { code: 'IQ', name: 'Iraq', flag: 'https://www.countryflags.io/IQ/flat/64.png', mobileCode: '+964' },
        { code: 'IE', name: 'Ireland', flag: 'https://www.countryflags.io/IE/flat/64.png', mobileCode: '+353' },
        { code: 'IM', name: 'Isle of Man', flag: 'https://www.countryflags.io/IM/flat/64.png', mobileCode: '+44' },
        { code: 'IL', name: 'Israel', flag: 'https://www.countryflags.io/IL/flat/64.png', mobileCode: '+972' },
        { code: 'IT', name: 'Italy', flag: 'https://www.countryflags.io/IT/flat/64.png', mobileCode: '+39' },
        { code: 'JM', name: 'Jamaica', flag: 'https://www.countryflags.io/JM/flat/64.png', mobileCode: '+1' },
        { code: 'JP', name: 'Japan', flag: 'https://www.countryflags.io/JP/flat/64.png', mobileCode: '+81' },
        { code: 'JE', name: 'Jersey', flag: 'https://www.countryflags.io/JE/flat/64.png', mobileCode: '+44' },
        { code: 'JO', name: 'Jordan', flag: 'https://www.countryflags.io/JO/flat/64.png', mobileCode: '+962' },
        { code: 'KZ', name: 'Kazakhstan', flag: 'https://www.countryflags.io/KZ/flat/64.png', mobileCode: '+7' },
        { code: 'KE', name: 'Kenya', flag: 'https://www.countryflags.io/KE/flat/64.png', mobileCode: '+254' },
        { code: 'KI', name: 'Kiribati', flag: 'https://www.countryflags.io/KI/flat/64.png', mobileCode: '+686' },
        { code: 'KP', name: 'Korea (Democratic People\'s Republic of)', flag: 'https://www.countryflags.io/KP/flat/64.png', mobileCode: '+850' },
        { code: 'KR', name: 'Korea (Republic of)', flag: 'https://www.countryflags.io/KR/flat/64.png', mobileCode: '+82' },
        { code: 'KW', name: 'Kuwait', flag: 'https://www.countryflags.io/KW/flat/64.png', mobileCode: '+965' },
        { code: 'KG', name: 'Kyrgyzstan', flag: 'https://www.countryflags.io/KG/flat/64.png', mobileCode: '+996' },
        { code: 'LA', name: 'Lao People\'s Democratic Republic', flag: 'https://www.countryflags.io/LA/flat/64.png', mobileCode: '+856' },
        { code: 'LV', name: 'Latvia', flag: 'https://www.countryflags.io/LV/flat/64.png', mobileCode: '+371' },
        { code: 'LB', name: 'Lebanon', flag: 'https://www.countryflags.io/LB/flat/64.png', mobileCode: '+961' },
        { code: 'LS', name: 'Lesotho', flag: 'https://www.countryflags.io/LS/flat/64.png', mobileCode: '+266' },
        { code: 'LR', name: 'Liberia', flag: 'https://www.countryflags.io/LR/flat/64.png', mobileCode: '+231' },
        { code: 'LY', name: 'Libya', flag: 'https://www.countryflags.io/LY/flat/64.png', mobileCode: '+218' },
        { code: 'LI', name: 'Liechtenstein', flag: 'https://www.countryflags.io/LI/flat/64.png', mobileCode: '+423' },
        { code: 'LT', name: 'Lithuania', flag: 'https://www.countryflags.io/LT/flat/64.png', mobileCode: '+370' },
        { code: 'LU', name: 'Luxembourg', flag: 'https://www.countryflags.io/LU/flat/64.png', mobileCode: '+352' },
        { code: 'MO', name: 'Macao', flag: 'https://www.countryflags.io/MO/flat/64.png', mobileCode: '+853' },
        { code: 'MK', name: 'Macedonia (the former Yugoslav Republic of)', flag: 'https://www.countryflags.io/MK/flat/64.png', mobileCode: '+389' },
        { code: 'MG', name: 'Madagascar', flag: 'https://www.countryflags.io/MG/flat/64.png', mobileCode: '+261' },
        { code: 'MW', name: 'Malawi', flag: 'https://www.countryflags.io/MW/flat/64.png', mobileCode: '+265' },
        { code: 'MY', name: 'Malaysia', flag: 'https://www.countryflags.io/MY/flat/64.png', mobileCode: '+60' },
        { code: 'MV', name: 'Maldives', flag: 'https://www.countryflags.io/MV/flat/64.png', mobileCode: '+960' },
        { code: 'ML', name: 'Mali', flag: 'https://www.countryflags.io/ML/flat/64.png', mobileCode: '+223' },
        { code: 'MT', name: 'Malta', flag: 'https://www.countryflags.io/MT/flat/64.png', mobileCode: '+356' },
        { code: 'MH', name: 'Marshall Islands', flag: 'https://www.countryflags.io/MH/flat/64.png', mobileCode: '+692' },
        { code: 'MQ', name: 'Martinique', flag: 'https://www.countryflags.io/MQ/flat/64.png', mobileCode: '+596' },
        { code: 'MR', name: 'Mauritania', flag: 'https://www.countryflags.io/MR/flat/64.png', mobileCode: '+222' },
        { code: 'MU', name: 'Mauritius', flag: 'https://www.countryflags.io/MU/flat/64.png', mobileCode: '+230' },
        { code: 'YT', name: 'Mayotte', flag: 'https://www.countryflags.io/YT/flat/64.png', mobileCode: '+262' },
        { code: 'MX', name: 'Mexico', flag: 'https://www.countryflags.io/MX/flat/64.png', mobileCode: '+52' },
        { code: 'FM', name: 'Micronesia (Federated States of)', flag: 'https://www.countryflags.io/FM/flat/64.png', mobileCode: '+691' },
        { code: 'MD', name: 'Moldova (Republic of)', flag: 'https://www.countryflags.io/MD/flat/64.png', mobileCode: '+373' },
        { code: 'MC', name: 'Monaco', flag: 'https://www.countryflags.io/MC/flat/64.png', mobileCode: '+377' },
        { code: 'MN', name: 'Mongolia', flag: 'https://www.countryflags.io/MN/flat/64.png', mobileCode: '+976' },
        { code: 'ME', name: 'Montenegro', flag: 'https://www.countryflags.io/ME/flat/64.png', mobileCode: '+382' },
        { code: 'MS', name: 'Montserrat', flag: 'https://www.countryflags.io/MS/flat/64.png', mobileCode: '+1' },
        { code: 'MA', name: 'Morocco', flag: 'https://www.countryflags.io/MA/flat/64.png', mobileCode: '+212' },
        { code: 'MZ', name: 'Mozambique', flag: 'https://www.countryflags.io/MZ/flat/64.png', mobileCode: '+258' },
        { code: 'MM', name: 'Myanmar', flag: 'https://www.countryflags.io/MM/flat/64.png', mobileCode: '+95' },
        { code: 'NA', name: 'Namibia', flag: 'https://www.countryflags.io/NA/flat/64.png', mobileCode: '+264' },
        { code: 'NR', name: 'Nauru', flag: 'https://www.countryflags.io/NR/flat/64.png', mobileCode: '+674' },
        { code: 'NP', name: 'Nepal', flag: 'https://www.countryflags.io/NP/flat/64.png', mobileCode: '+977' },
        { code: 'NL', name: 'Netherlands', flag: 'https://www.countryflags.io/NL/flat/64.png', mobileCode: '+31' },
        { code: 'NC', name: 'New Caledonia', flag: 'https://www.countryflags.io/NC/flat/64.png', mobileCode: '+687' },
        { code: 'NZ', name: 'New Zealand', flag: 'https://www.countryflags.io/NZ/flat/64.png', mobileCode: '+64' },
        { code: 'NI', name: 'Nicaragua', flag: 'https://www.countryflags.io/NI/flat/64.png', mobileCode: '+505' },
        { code: 'NE', name: 'Niger', flag: 'https://www.countryflags.io/NE/flat/64.png', mobileCode: '+227' },
        { code: 'NG', name: 'Nigeria', flag: 'https://www.countryflags.io/NG/flat/64.png', mobileCode: '+234' },
        { code: 'NU', name: 'Niue', flag: 'https://www.countryflags.io/NU/flat/64.png', mobileCode: '+683' },
        { code: 'NF', name: 'Norfolk Island', flag: 'https://www.countryflags.io/NF/flat/64.png', mobileCode: '+672' },
        { code: 'MP', name: 'Northern Mariana Islands', flag: 'https://www.countryflags.io/MP/flat/64.png', mobileCode: '+1' },
        { code: 'NO', name: 'Norway', flag: 'https://www.countryflags.io/NO/flat/64.png', mobileCode: '+47' },
        { code: 'OM', name: 'Oman', flag: 'https://www.countryflags.io/OM/flat/64.png', mobileCode: '+968' },
        { code: 'PK', name: 'Pakistan', flag: 'https://www.countryflags.io/PK/flat/64.png', mobileCode: '+92' },
        { code: 'PW', name: 'Palau', flag: 'https://www.countryflags.io/PW/flat/64.png', mobileCode: '+680' },
        { code: 'PS', name: 'Palestine, State of', flag: 'https://www.countryflags.io/PS/flat/64.png', mobileCode: '+970' },
        { code: 'PA', name: 'Panama', flag: 'https://www.countryflags.io/PA/flat/64.png', mobileCode: '+507' },
        { code: 'PG', name: 'Papua New Guinea', flag: 'https://www.countryflags.io/PG/flat/64.png', mobileCode: '+675' },
        { code: 'PY', name: 'Paraguay', flag: 'https://www.countryflags.io/PY/flat/64.png', mobileCode: '+595' },
        { code: 'PE', name: 'Peru', flag: 'https://www.countryflags.io/PE/flat/64.png', mobileCode: '+51' },
        { code: 'PH', name: 'Philippines', flag: 'https://www.countryflags.io/PH/flat/64.png', mobileCode: '+63' },
        { code: 'PN', name: 'Pitcairn', flag: 'https://www.countryflags.io/PN/flat/64.png', mobileCode: '+64' },
        { code: 'PL', name: 'Poland', flag: 'https://www.countryflags.io/PL/flat/64.png', mobileCode: '+48' },
        { code: 'PT', name: 'Portugal', flag: 'https://www.countryflags.io/PT/flat/64.png', mobileCode: '+351' },
        { code: 'PR', name: 'Puerto Rico', flag: 'https://www.countryflags.io/PR/flat/64.png', mobileCode: '+1' },
        { code: 'QA', name: 'Qatar', flag: 'https://www.countryflags.io/QA/flat/64.png', mobileCode: '+974' },
        { code: 'RE', name: 'Réunion', flag: 'https://www.countryflags.io/RE/flat/64.png', mobileCode: '+262' },
        { code: 'RO', name: 'Romania', flag: 'https://www.countryflags.io/RO/flat/64.png', mobileCode: '+40' },
        { code: 'RU', name: 'Russian Federation', flag: 'https://www.countryflags.io/RU/flat/64.png', mobileCode: '+7' },
        { code: 'RW', name: 'Rwanda', flag: 'https://www.countryflags.io/RW/flat/64.png', mobileCode: '+250' },
        { code: 'BL', name: 'Saint Barthélemy', flag: 'https://www.countryflags.io/BL/flat/64.png', mobileCode: '+590' },
        { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', flag: 'https://www.countryflags.io/SH/flat/64.png', mobileCode: '+290' },
        { code: 'KN', name: 'Saint Kitts and Nevis', flag: 'https://www.countryflags.io/KN/flat/64.png', mobileCode: '+1' },
        { code: 'LC', name: 'Saint Lucia', flag: 'https://www.countryflags.io/LC/flat/64.png', mobileCode: '+1' },
        { code: 'MF', name: 'Saint Martin (French part)', flag: 'https://www.countryflags.io/MF/flat/64.png', mobileCode: '+590' },
        { code: 'PM', name: 'Saint Pierre and Miquelon', flag: 'https://www.countryflags.io/PM/flat/64.png', mobileCode: '+508' },
        { code: 'VC', name: 'Saint Vincent and the Grenadines', flag: 'https://www.countryflags.io/VC/flat/64.png', mobileCode: '+1' },
        { code: 'WS', name: 'Samoa', flag: 'https://www.countryflags.io/WS/flat/64.png', mobileCode: '+685' },
        { code: 'SM', name: 'San Marino', flag: 'https://www.countryflags.io/SM/flat/64.png', mobileCode: '+378' },
        { code: 'ST', name: 'Sao Tome and Principe', flag: 'https://www.countryflags.io/ST/flat/64.png', mobileCode: '+239' },
        { code: 'SA', name: 'Saudi Arabia', flag: 'https://www.countryflags.io/SA/flat/64.png', mobileCode: '+966' },
        { code: 'SN', name: 'Senegal', flag: 'https://www.countryflags.io/SN/flat/64.png', mobileCode: '+221' },
        { code: 'RS', name: 'Serbia', flag: 'https://www.countryflags.io/RS/flat/64.png', mobileCode: '+381' },
        { code: 'SC', name: 'Seychelles', flag: 'https://www.countryflags.io/SC/flat/64.png', mobileCode: '+248' },
        { code: 'SL', name: 'Sierra Leone', flag: 'https://www.countryflags.io/SL/flat/64.png', mobileCode: '+232' },
        { code: 'SG', name: 'Singapore', flag: 'https://www.countryflags.io/SG/flat/64.png', mobileCode: '+65' },
        { code: 'SX', name: 'Sint Maarten (Dutch part)', flag: 'https://www.countryflags.io/SX/flat/64.png', mobileCode: '+1' },
        { code: 'SK', name: 'Slovakia', flag: 'https://www.countryflags.io/SK/flat/64.png', mobileCode: '+421' },
        { code: 'SI', name: 'Slovenia', flag: 'https://www.countryflags.io/SI/flat/64.png', mobileCode: '+386' },
        { code: 'SB', name: 'Solomon Islands', flag: 'https://www.countryflags.io/SB/flat/64.png', mobileCode: '+677' },
        { code: 'SO', name: 'Somalia', flag: 'https://www.countryflags.io/SO/flat/64.png', mobileCode: '+252' },
        { code: 'ZA', name: 'South Africa', flag: 'https://www.countryflags.io/ZA/flat/64.png', mobileCode: '+27' },
        { code: 'GS', name: 'South Georgia and the South Sandwich Islands', flag: 'https://www.countryflags.io/GS/flat/64.png', mobileCode: '+500' },
        { code: 'SS', name: 'South Sudan', flag: 'https://www.countryflags.io/SS/flat/64.png', mobileCode: '+211' },
        { code: 'ES', name: 'Spain', flag: 'https://www.countryflags.io/ES/flat/64.png', mobileCode: '+34' },
        { code: 'LK', name: 'Sri Lanka', flag: 'https://www.countryflags.io/LK/flat/64.png', mobileCode: '+94' },
        { code: 'SD', name: 'Sudan', flag: 'https://www.countryflags.io/SD/flat/64.png', mobileCode: '+249' },
        { code: 'SR', name: 'Suriname', flag: 'https://www.countryflags.io/SR/flat/64.png', mobileCode: '+597' },
        { code: 'SJ', name: 'Svalbard and Jan Mayen', flag: 'https://www.countryflags.io/SJ/flat/64.png', mobileCode: '+47' },
        { code: 'SZ', name: 'Swaziland', flag: 'https://www.countryflags.io/SZ/flat/64.png', mobileCode: '+268' },
        { code: 'SE', name: 'Sweden', flag: 'https://www.countryflags.io/SE/flat/64.png', mobileCode: '+46' },
        { code: 'CH', name: 'Switzerland', flag: 'https://www.countryflags.io/CH/flat/64.png', mobileCode: '+41' },
        { code: 'SY', name: 'Syrian Arab Republic', flag: 'https://www.countryflags.io/SY/flat/64.png', mobileCode: '+963' },
        { code: 'TW', name: 'Taiwan', flag: 'https://www.countryflags.io/TW/flat/64.png', mobileCode: '+886' },
        { code: 'TJ', name: 'Tajikistan', flag: 'https://www.countryflags.io/TJ/flat/64.png', mobileCode: '+992' },
        { code: 'TZ', name: 'Tanzania, United Republic of', flag: 'https://www.countryflags.io/TZ/flat/64.png', mobileCode: '+255' },
        { code: 'TH', name: 'Thailand', flag: 'https://www.countryflags.io/TH/flat/64.png', mobileCode: '+66' },
        { code: 'TL', name: 'Timor-Leste', flag: 'https://www.countryflags.io/TL/flat/64.png', mobileCode: '+670' },
        { code: 'TG', name: 'Togo', flag: 'https://www.countryflags.io/TG/flat/64.png', mobileCode: '+228' },
        { code: 'TK', name: 'Tokelau', flag: 'https://www.countryflags.io/TK/flat/64.png', mobileCode: '+690' },
        { code: 'TO', name: 'Tonga', flag: 'https://www.countryflags.io/TO/flat/64.png', mobileCode: '+676' },
        { code: 'TT', name: 'Trinidad and Tobago', flag: 'https://www.countryflags.io/TT/flat/64.png', mobileCode: '+1' },
        { code: 'TN', name: 'Tunisia', flag: 'https://www.countryflags.io/TN/flat/64.png', mobileCode: '+216' },
        { code: 'TR', name: 'Turkey', flag: 'https://www.countryflags.io/TR/flat/64.png', mobileCode: '+90' },
        { code: 'TM', name: 'Turkmenistan', flag: 'https://www.countryflags.io/TM/flat/64.png', mobileCode: '+993' },
        { code: 'TC', name: 'Turks and Caicos Islands', flag: 'https://www.countryflags.io/TC/flat/64.png', mobileCode: '+1' },
        { code: 'TV', name: 'Tuvalu', flag: 'https://www.countryflags.io/TV/flat/64.png', mobileCode: '+688' },
        { code: 'UG', name: 'Uganda', flag: 'https://www.countryflags.io/UG/flat/64.png', mobileCode: '+256' },
        { code: 'UA', name: 'Ukraine', flag: 'https://www.countryflags.io/UA/flat/64.png', mobileCode: '+380' },
        { code: 'AE', name: 'United Arab Emirates', flag: 'https://www.countryflags.io/AE/flat/64.png', mobileCode: '+971' },
        { code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', flag: 'https://www.countryflags.io/GB/flat/64.png', mobileCode: '+44' },
        { code: 'US', name: 'United States of America', flag: 'https://www.countryflags.io/US/flat/64.png', mobileCode: '+1' },
        { code: 'UM', name: 'United States Minor Outlying Islands', flag: 'https://www.countryflags.io/UM/flat/64.png', mobileCode: '+1' },
        { code: 'UY', name: 'Uruguay', flag: 'https://www.countryflags.io/UY/flat/64.png', mobileCode: '+598' },
        { code: 'UZ', name: 'Uzbekistan', flag: 'https://www.countryflags.io/UZ/flat/64.png', mobileCode: '+998' },
        { code: 'VU', name: 'Vanuatu', flag: 'https://www.countryflags.io/VU/flat/64.png', mobileCode: '+678' },
        { code: 'VE', name: 'Venezuela (Bolivarian Republic of)', flag: 'https://www.countryflags.io/VE/flat/64.png', mobileCode: '+58' },
        { code: 'VN', name: 'Viet Nam', flag: 'https://www.countryflags.io/VN/flat/64.png', mobileCode: '+84' },
        { code: 'VG', name: 'Virgin Islands (British)', flag: 'https://www.countryflags.io/VG/flat/64.png', mobileCode: '+1' },
        { code: 'VI', name: 'Virgin Islands (U.S.)', flag: 'https://www.countryflags.io/VI/flat/64.png', mobileCode: '+1' },
        { code: 'WF', name: 'Wallis and Futuna', flag: 'https://www.countryflags.io/WF/flat/64.png', mobileCode: '+681' },
        { code: 'EH', name: 'Western Sahara', flag: 'https://www.countryflags.io/EH/flat/64.png', mobileCode: '+212' },
        { code: 'YE', name: 'Yemen', flag: 'https://www.countryflags.io/YE/flat/64.png', mobileCode: '+967' },
        { code: 'ZM', name: 'Zambia', flag: 'https://www.countryflags.io/ZM/flat/64.png', mobileCode: '+260' },
        { code: 'ZW', name: 'Zimbabwe', flag: 'https://www.countryflags.io/ZW/flat/64.png', mobileCode: '+263' }
    ];
    const defaultCountry = countries.find(country => country.code === 'IN');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry.mobileCode);

    const handleInputChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleCountryChange = (value,data,name) => {
        console.log(value,data,name)
       SetData({...Data,[name]:value})
    };




    return (
        <>
            <div className="container-form">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../../pages/images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <form onSubmit={OnSubmit} encType="multipart/form-data">
                        <div className="Loginerror">
                            <p>{error}</p>
                        </div>
                        <div className="row">
                            {field_objects &&
                                field_objects.map((fieldItem, i) => {
                                    const isRequired = fieldItem.required ? '*' : '';
                                    const hasError = Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                                    const isRequiredAndEmpty = fieldItem.required && !Data[fieldItem.fieldName];
                                    return (
                                        <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
                                            {fieldItem.fieldType === "string" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label} <span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} style={{textTransform:fieldItem.textTransform?"uppercase":""}}/>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "email" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="email" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "url" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="nuput-box">
                                                        <input type="url" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "boolean" &&
                                                <div className="form-group">
                                                    <label className="form-check-label ari-radio-label">
                                                        <input className="ari-radio" type="checkbox" name={fieldItem.fieldName} checked={Data[fieldItem.fieldName]} onChange={Handlecheckboxchange} required={fieldItem.required} disabled={readOnly} /> {fieldItem.label}
                                                    </label>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Tooltip for <strong>{fieldItem.label}</strong>.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button style={{ border: "none", background: "#FFF" }}>
                                                                <MdInfo />
                                                            </button>
                                                        </OverlayTrigger>
                                                    )}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "file" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {Data && Data[fieldItem.fieldName] && (
                                                        <a href={
                                                            typeof Data[fieldItem.fieldName] === 'string'
                                                                ? Data[fieldItem.fieldName]  // Use the string as-is if it's a URL or file path
                                                                : URL.createObjectURL(Data[fieldItem.fieldName])  // Create URL for File object
                                                        } target="_blank"
                                                        >
                                                            {getFileIcon(Data[fieldItem.fieldName])}

                                                        </a>
                                                    )}
                                                    <div className="input-box">
                                                        <input type="file" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleFiles} required={!Data[fieldItem.fieldName] && fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {fieldItem.fieldType === "date" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="date" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "time" &&
                                                <div className="form-group">

                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="time" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "integer" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="number" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "Phone" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box" style={{position:"relative"}}>
                                                        <div>
                                                    <Select styles={{position:"relative"}} className={FieldErrors[fieldItem.fieldName] ? "invalid-error w-1" : "w-1"} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                {code:res.country_calling_code, "value": res.id, "label": `${res.country_calling_code} ${res[fieldItem.displayField]}` }
                                                            ))} 
                                                                value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data["country_calling_code"])} onChange={(selectedOption) => handleSelect(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} autoComplete="off" />
                                                                </div>
                                                        
                                                    <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                              {fieldItem.fieldType === "Doctor" &&
                                            <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                            <label>{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                            <div className="input-box" style={{ position: "relative" }}>
                                                <div style={{ position: "relative" }}>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`}
                                                        placeholder={fieldItem.label}
                                                        name={fieldItem.fieldName}
                                                        maxLength={fieldItem.max_length ? fieldItem.max_length : null}
                                                        onChange={HandleChange}
                                                        defaultValue={Data[fieldItem.fieldName]}
                                                        disabled={readOnly}
                                                        style={{ paddingLeft: "70px",textTransform:fieldItem.textTransform?"uppercase":""}}

                                                    />
                                                    <Select
                                                        className={FieldErrors[fieldItem.fieldName] ? "invalid-error position-static" : "position-static"}
                                                        options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                            { "value": res.id, "label": `${res.code}` }
                                                        ))}
                                                        value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data["title"])}
                                                        onChange={(selectedOption) => handleSelectDoctor(selectedOption, fieldItem.fieldName)}
                                                        isDisabled={readOnly}
                                                        autoComplete="off"
                                                        required={fieldItem.required}
                                                        styles={{
                                                            control: provided => ({
                                                                ...provided,
                                                                border: "none", // Remove right border
                                                                border: "1px solid #DDD", 
                                                                boxShadow: "none",
                                                                position: "absolute",
                                                                top: 0,
                                                                left:0,
                                                                zIndex: 1,
                                                                width: "4.2em", // Adjust the width to fit three letters
                                                                minWidth: "4.2em",
                                                            }),
                                                            dropdownIndicator: provided => ({
                                                                ...provided,
                                                                display: "none",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {FieldErrors[fieldItem.fieldName] && (
                                                <div className="invalid">
                                                    {FieldErrors[fieldItem.fieldName]}
                                                </div>
                                            )}
                                        </div>
                                        
                                         
                                          
                                            }
                                            {fieldItem.fieldType === "password" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="password" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "radio" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <br />
                                                    {fieldItem.options &&
                                                        fieldItem.options.map((optionItem, index) => {
                                                            return (
                                                                <div className="form-check-inline" key={index}>
                                                                    <label className="form-check-label ari-radio-label">
                                                                        <input type="radio" className="ari-radio" name={fieldItem.fieldName} checked={Data[fieldItem.fieldName] === optionItem} defaultValue={Data[fieldItem.fieldName]} onChange={(e) => HandleRadioChange(e, optionItem)} required={fieldItem.required} disabled={readOnly} />{optionItem}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* {fieldItem.required && (
                                                        <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                          <Tooltip id="tooltip-top">
                                                            Tooltip for <strong>{fieldItem.label}</strong>.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <button style={{border:"none",background:"#FFF"}}>
                                                        <MdInfo />
                                                        </button>
                                                      </OverlayTrigger>
                                                    )} */}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "textarea" &&
                                                <div className="form-group">
                                                    <label>{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <textarea className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} rows="2" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readOnly}></textarea>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={fieldItem.options} placeholder={fieldItem.label} defaultValue={fieldItem.options.find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { "value": res.id, "label": res[fieldItem.displayField] }
                                                            ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                                isLoading={loading1}
                                                                onInputChange={handleSearch} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} autoComplete="off" />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dynamicSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {console.log(Data[fieldItem.fieldName])}
                                                    <div className="input-box">
                                                        <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={SelectDynamicOptions[fieldItem.fieldName] && SelectDynamicOptions[fieldItem.fieldName].map((res) => (
                                                            { "value": res.id, "label": res[fieldItem.displayField] }
                                                        ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName, fieldItem.fieldType)}
                                                            isLoading={loading1}
                                                            onInputChange={handleSearch} value={SelectDynamicOptions[fieldItem.fieldName] && SelectDynamicOptions[fieldItem.fieldName].map((res) => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(find => find.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} autoComplete="off" />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicMultiSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <Select isMulti options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label}
                                                        onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                        isLoading={loading1}
                                                        onInputChange={handleSearch}
                                                        value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ value: res.id, label: res[fieldItem.displayField] })).filter(option => (Data[fieldItem.fieldName] || []).includes(option.value))}
                                                        onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, fieldItem.fieldName)}  isDisabled={readOnly} />
                                                </div>
                                            }

                                            {/* {
                                            fieldItem.fieldType === "DynamicSelectOptions" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}:</label>
                                                <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                    <option value="">SELECT {fieldItem.label}</option>
                                                    {
                                                       SelectDynamicOptions.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.id} key={i} selected={optionItem.id===Data[fieldItem.fieldName]?Data[fieldItem.fieldName]:undefined} >{optionItem[fieldItem.displayField]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        } */}
                                            {fieldItem.fieldType === "mulitipulFiles" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                <input className="form-control" type="file" id="formFileMultiple" name={fieldItem.fieldName} multiple onChange={HandleMultiPulFiles} required={fieldItem.required} disabled={readOnly} />
                                            </div>
                                            }
                                            {fieldItem.fieldType === "status" && <div key={i} className="form-group form-check form-switch">
                                                <div>
                                                    <label className="form-check-label">STATUS</label>
                                                </div>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} disabled={readOnly} />

                                            </div>
                                            }
                                            {
                                                fieldItem.fieldType === "ReactQuill" &&
                                                <div className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <ReactQuillComponent className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} SetData={SetData} Data={Data} name={fieldItem.fieldName} value={Data[fieldItem.fieldName]} readOnly={readOnly} required={fieldItem.required} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "HandleInfinitiSelect" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { "value": res.id, "label": res[fieldItem.displayField] }
                                                            ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                                isLoading={loading1}
                                                                onInputChange={handleSearch} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readOnly} autoComplete="off" />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {!readOnly &&
                            <button type="submit" className={`btn_primary_gradient ${button ? button : ""}`} disabled={LoadingButton}>
                                {LoadingButton ? (<div>
                                    <img src={require('../../pages/images/loader.gif')} alt="Loading..." className="loader-image" />
                                </div>) : (buttonTitle ? buttonTitle : "Submit")}
                            </button>
                        }
                    </form>

                )}
            </div>

        </>
    )
}
export { FormParser }