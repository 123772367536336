import React, { useState } from "react";
import './pages.css';
import Slider from "react-slick";
import { Footer } from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { handleClose, handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";

const Home = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.show);
    const modalTitle = useSelector((state) => state.modal.modalTitle);
    const isRegistered = useSelector((state) => state.modal.isRegistered);

    const openModal = () => {
        dispatch(handleShow());
    };

    const closeModal = () => {
        dispatch(handleClose());
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        // autoplay:true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ],
        appendDots: dots => (
            <div
                style={{
                    position: "relative",
                    width: "100%"
                }}
            >
                <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                    <ul style={{ margin: "0px" }} className="t_dots"> {dots} </ul>
                </div>
            </div>
        )
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    const handleNavigate = (url) => {
        openModal();
        switch (url) {
            case '/home-nurse':
                dispatch(setModalTitle('Home Nurse'));
                break;
            case '/care-giver':
                dispatch(setModalTitle('Care Giver'));
                break;
            case '/baby-care-services':
                dispatch(setModalTitle('Baby Care'));
                break;
            case '/home-icu-setup':
                dispatch(setModalTitle('Home ICU Setup'));
                break;
            case '/medical-equipment':
                dispatch(setModalTitle('Medical Equipment'));
                break;
            case '/doctor-consultation':
                dispatch(setModalTitle('Doctor Consultation'));
                break;
            case '/physiotherapy':
                dispatch(setModalTitle('Physiotherapy'));
                break;
            case '/medicine-home-delivery':
                dispatch(setModalTitle('Medicine Home Delivery'));
                break;
            case '/home-health-checks':
                dispatch(setModalTitle('Health Checks'));
                break;
            case '/diagnostics':
                dispatch(setModalTitle('Diagnostics'));
                break;
            case '/radiology':
                dispatch(setModalTitle('Radiology'));
                break;
            default:
                dispatch(setModalTitle('Home'));
                break;
        }
    }

    return (
        <>
            <section className="trusted">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mobile">Your Trusted Partner For<br /> Home Health Care</h2>
                            <img className="img-fluid big_img" src={require('./images/trusted.webp')} alt="trusted" />
                        </div>
                        <div className="col-md-6 right">
                            <h2 className="desktop">
                                Your Trusted Partner For<br />
                                Home Health Care
                            </h2>
                            <div className="image_menu">
                                <div className="grid_3">
                                    <div onClick={() => handleNavigate('/home-nurse')}>
                                        <div className="img_container">
                                            <img src={require('./images/nurse.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Home Nurse</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/care-giver')}>
                                        <div className="img_container">
                                            <img src={require('./images/chair.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Care Giver</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/baby-care-services')}>
                                        <div className="img_container">
                                            <img src={require('./images/baby.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Baby Care</p>
                                    </div>
                                </div>
                                <div className="grid_3">
                                    <div onClick={() => handleNavigate('/home-icu-setup')}>
                                        <div className="img_container">
                                            <img src={require('./images/bed.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Home ICU Setup</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/medical-equipment')}>
                                        <div className="img_container">
                                            <img src={require('./images/wheel.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Medical Equipment</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/doctor-consultation')}>
                                        <div className="img_container">
                                            <img src={require('./images/doc.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Doctor Consultation</p>
                                    </div>
                                </div>
                                <div className="grid_3">
                                    <div onClick={() => handleNavigate('/physiotherapy')}>
                                        <div className="img_container">
                                            <img src={require('./images/sleep.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Physiotherapy</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/medicine-home-delivery')}>
                                        <div className="img_container">
                                            <img src={require('./images/bike.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Medicine Home Delivery</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/home-health-checks')}>
                                        <div className="img_container">
                                            <img src={require('./images/patient.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Health Checks</p>
                                    </div>
                                </div>
                                <div className="grid_2">
                                    <div onClick={() => handleNavigate('/diagnostics')}>
                                        <div className="img_container">
                                            <img src={require('./images/tube.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Diagnostics</p>
                                    </div>
                                    <div onClick={() => handleNavigate('/radiology')}>
                                        <div className="img_container">
                                            <img src={require('./images/scan.png')} className="img-fluid" alt="nurse" />
                                        </div>
                                        <p>Radiology</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="img-fluid big_img" src={require('./images/doctor.webp')} alt="doctor" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Why Choose Us</h2>
                            <div>
                                <h3>Tailored To Your Needs</h3>
                                <h4>We understand that one size does not fit all. Personalized care tailored to your needs,
                                    right in the comfort of your home</h4>
                            </div>
                            <div>
                                <h3>Family Comes First, Always</h3>
                                <h4>We understand the importance of family and strive to keep you connected and
                                    supported throughout the journey</h4>
                            </div>
                            <div>
                                <h3>We’re The Best</h3>
                                <h4>We prioritize well being above all. We’re the best because we blend expertise,
                                    compassion, and convenience seamlessly</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="healthcare">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="desktop">Healthcare That Revolves Around You<br />
                                In Your Own Space</h2>
                            <h2 className="mobile">Healthcare That Revolves<br /> Around You
                                In Your Own<br /> Space</h2>
                            <button className="btn_primary_gradient">Book Now</button>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src={require('./images/hc_right.png')} alt="healthcare" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bringing">
                <img className="img-fluid big_img" src={require('./images/bringing.png')} alt="bringing" />
                <div className="right">
                    <h3>Bringing Care Where You Need It The Most</h3>
                    <p>We believe in the power of human connection for homebound patients. Many people prefer to be home during healing phase surrounded by familiar comforts. Homebound patients face constant challenges of frequent hospital visits for health check ups or services. We work with you to understand your needs and develop a personalized care plan, matching services that suit you</p>
                </div>
            </section>
            <section className="timeline_section">
                <h2 className="text-center">How It Works</h2>
                <div className="timeline">
                    <div className="tc-parent">
                        <div className="tc right">
                            <div className="content">
                                <h2>01</h2>
                                <span>
                                    <h3>Quick registration</h3>
                                    <p className="quick">Register in 3 easy steps</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="tc-parent">
                        <div className="tc left">
                            <div className="content">
                                <h2 className="inactive">02</h2>
                                <span>
                                    <h3>Assessment</h3>
                                    <p>Our care expert will evaluate your needs & develop
                                        a customized care plan, along with a cost estimate
                                        that fits your budget</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="tc-parent">
                        <div className="tc right">
                            <div className="content">
                                <h2>03</h2>
                                <span>
                                    <h3>Right Resource Allocation</h3>
                                    <p>On service confirmation our delivery team deploys
                                        right resource</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="tc-parent">
                        <div className="tc left">
                            <div className="content">
                                <h2>04</h2>
                                <span>
                                    <h3>Authenticated Service Delivery <br />
                                        With Close Monitoring</h3>
                                    <p>Our trained professionals will deliver your customized
                                        care needs with utmost satisfaction, utilizing industry
                                        leading monitoring tools</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home">
                <div className="absolute_img"></div>
                <div className="container">
                    <h2>Home Is The New Health Hub</h2>
                    <h4>Let us bring care to you, right where you are</h4>
                    <div className="grid_4">
                        <div className="grid_card">
                            <div className="top">
                                <span><h2>01</h2></span>
                                <h3>Personal Care</h3>
                            </div>
                            <h4>Daily routines such as grooming,
                                showering, incontinence care,
                                mobility assistance, & so on</h4>
                        </div>
                        <div className="grid_card">
                            <div className="top">
                                <span><h2>02</h2></span>
                                <h3>Home <br />
                                    Rehabilitation</h3>
                            </div>
                            <h4>Targeted at restoring and improving
                                mobility at different levels of need</h4>
                        </div>
                        <div className="grid_card">
                            <div className="top">
                                <span><h2>03</h2></span>
                                <h3>Skilled Nursing<br />
                                    Procedures</h3>
                            </div>
                            <h4>Includes wound dressing, stoma
                                care, change of feeding tubes,
                                monitoring vitals, administer
                                medicines, & so on</h4>
                        </div>
                        <div className="grid_card">
                            <div className="top">
                                <span><h2>04</h2></span>
                                <h3>Night Care</h3>
                            </div>
                            <h4>Care provided to assist with toileting
                                and nursing needs, prevent wandering,
                                and reduce fall risk</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 left">
                            <h2>Care At Your Fingertips</h2>
                            <ul>
                                <li>Book care on the go with our mobile app</li>
                                <li>Enjoy seamless scheduling, personalized care options, & real time updates</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid big_img" src={require('./images/phone_home.webp')} alt="phone" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="our">
                <h2>Our Sanctuary Of Thought</h2>
                <div className="container">
                    <div className="grid_3">
                        <div>
                            <div className="grid_card">
                                <img className="img-fluid" src={require('./images/our_1.webp')} alt="heart" />
                            </div>
                            <p>
                                Care Givers Touch<br />
                                Makes A House Feel Like Home
                            </p>
                        </div>
                        <div>
                            <div className="grid_card">
                                <img className="img-fluid" src={require('./images/our_2.webp')} alt="heart" />
                            </div>
                            <p>
                                Parenting<br />
                                Toughest Job You’ll Ever Love
                            </p>
                        </div>
                        <div>
                            <div className="grid_card">
                                <img className="img-fluid" src={require('./images/our_3.webp')} alt="heart" />
                            </div>
                            <p>
                                ICU Setup At Home<br />
                                A Safe Haven For Healing
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="home-title">Testimonials</p>
                    <div className="slider-container">
                        <Slider {...settings}>
                            <div className="slide_item_parent">
                                <div className="slide_item">
                                    <img src={require('./images/quotes.webp')} alt="quotes" />
                                    <h4>My mother received exceptional care right in the comfort
                                        of our home. The nurseswere professional, compassionate,
                                        and went above and beyond  ensure her well-being. They provided
                                        personalized care tailored to her needs,making a difficult time much more .....</h4>
                                    <div className="client">
                                        <img src={require('./images/doctor_2.webp')} alt="client" />
                                        <div>
                                            <h4>Pelican Steve</h4>
                                            {/* <p>Neurologist</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slide_item_parent">
                                <div className="slide_item">
                                    <img src={require('./images/quotes.webp')} alt="quotes" />
                                    <h4>Choosing home baby care was the best decision we made for our
                                        newborn. The nurses were amazing - they provided expert guidance,
                                        support, and care right in the comfort of our home. They helped us
                                        navigate the challenges of early parenthood, from ....</h4>
                                    <div className="client">
                                        <img src={require('./images/doctor_2.webp')} alt="client" />
                                        <div>
                                            <h4>Pelican Steve</h4>
                                            {/* <p>Neurologist</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="contact">
                <div className="container">
                    <h2 className="mobile">Speak To Our Expert</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="desktop">Speak To Our Expert</h2>
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" placeholder="Name" name="name" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" className="form-control" placeholder="Email" name="email" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="tel" className="form-control" placeholder="Phone" name="phone" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" placeholder="Query" name="query" required />
                                    </div>
                                </div>
                                <button type="submit" className="btn_primary_gradient">Submit</button>
                            </form>
                        </div>
                        <div className="col-md-4 ord_1">
                            <img className="img-fluid big_img" src={require('./images/doctor_2.webp')} alt="doctor" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { Home }