import { CiSearch } from "react-icons/ci";
import { FaAnglesRight } from "react-icons/fa6";
import Select from 'react-select';
import { FaStar } from "react-icons/fa";
import { selectStyles } from "./StyleGuide";
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { CustomRadioButton } from "../components/CustomRadioButton/CustomRadioButton";
import axiosConfig from "../Service/axiosConfig";
const BookingsPage = () => {
    const [show, setShow] = useState(false);
    const [active, setActive] = useState('Processing');
    const [modalTitle, setModalTitle] = useState('Provide Feedback');
    const [bookingFor, setBookingFor] = useState('self');
    const [gender, setGender] = useState('');
    const [bookings, setBookings] = useState([]);
    const handleFileUploadClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById('prescription').click();
    };
    const handleInputClick = (e) => {
        e.stopPropagation();
    };
    const onHide = () => {
        setShow(false);
    }

    const handleClick = (title) => {
        setShow(true);
        setModalTitle(title);
    }

    const GetBookings = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/leads/?user=${localStorage.getItem('user_id')}&status=${active}`);
            console.log(response.data);
            setBookings(response.data.results);
        } catch (error) {
            console.log("Error getting bookings : ", error);
        }
    }

    const getMonthName = (date) => {
        const [year, month, day] = date.split('-');
        const dateObj = new Date(year, month - 1, day); // month is 0-indexed
        const options = { day : 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-IN', options).format(dateObj);
    }

    useEffect(() => {
        GetBookings();
    }, [active]);
    return (
        <div className="patient-dashboard-child">
            <h3 className="desktop">Bookings</h3>
            <div className="form-container">
                <div class="button-group">
                    <button className={`${active === 'Processing' ? 'btn_primary_gradient' : 'btn_secondary_2'} left`} onClick={() => setActive('Processing')}>
                        In Progress
                    </button>
                    <button className={`${active === 'Completed' ? 'btn_primary_gradient' : 'btn_secondary_2'} right`} onClick={() => setActive('Completed')}>
                        Completed
                    </button>
                </div>
                <div className="filters">
                    <div className="custom-select-container">
                        <Select
                            options={[
                                { value: '1', label: 'Family Member' },
                                { value: '2', label: 'Friend' },
                                { value: '3', label: 'Guardian' },
                            ]}
                            styles={selectStyles}
                        />
                    </div>
                    <div className="search-box">
                        <CiSearch size={20} />
                        <input type="text" placeholder="Search" />
                    </div>
                </div>

                {bookings.length > 0 ?
                    bookings.map((booking) => {
                        return (

                            <div className="bookings-list">
                                <div className="data">
                                    <div className="left-1">
                                        <div className="date-time">
                                            <h4>{getMonthName(booking.created_date)}</h4>
                                            {/* <h4>13 Nov<br />2024<br />10:30PM</h4> */}
                                        </div>
                                        <div className="left-2">
                                            <h4 className="wt_500">{booking.user?.first_name} {booking.user?.last_name}</h4>
                                            {/* <h4 className="wt_500">Kumar (M) 23Y</h4> */}
                                            <h4>#{booking.id}</h4>
                                            <h4 className="wt_500">{booking.category?.name}</h4>
                                        </div>
                                    </div>
                                    <div className="left-3">
                                        {/* <h4 className="wt_500">₹ 5000</h4> */}
                                        {/* {active === 'Processing' ?
                                            <h4 className="status">{bookings.status}</h4>
                                            : <h4 className="status">Completed(4.5 <FaStar color="yellow" />)</h4>
                                        } */}
                                        <h4 className="status">{booking.status}</h4>
                                        {/* <h4>10/02/2024 To 12/02/2024</h4> */}
                                    </div>
                                </div>
                                <div className="links">
                                    <div className="left">
                                        <h4 className="wt_500" onClick={() => handleClick('Provide Feedback')}><FaAnglesRight size={10} />Provide Feedback</h4>
                                        <h4 className="wt_500" onClick={() => handleClick('Patient Demographics')}><FaAnglesRight size={10} />Patient Demographics</h4>
                                    </div>
                                    <div className="right">
                                        <button className="btn_primary_gradient">View Care Plan</button>
                                        <h4 className="wt_500" onClick={() => handleClick('Prescription')}><FaAnglesRight size={10} />Prescription</h4>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <h4>No bookings yet</h4>
                }
            </div>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h3>{modalTitle}</h3>
                    <IoIosCloseCircleOutline size={18} onClick={onHide} className="close-icon" />
                </Modal.Header>
                <Modal.Body>
                    {modalTitle === "Provide Feedback" &&
                        <>
                            <div className="pf-row">
                                <h4 className="pf-title">Provider Rating</h4>
                                <div>
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                </div>
                            </div>
                            <div className="pf-row">
                                <h4 className="pf-title">Booking Experience</h4>
                                <div>
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                    <MdOutlineStarPurple500 size={20} color="#D9D9D9" />
                                </div>
                            </div>
                            <div className="pf-row comments">
                                <h4 className="pf-title">Comments</h4>
                                <textarea className="form-control pf-textarea">

                                </textarea>
                            </div>
                            <button className="btn_primary_gradient pf-btn">Submit feedback</button>
                        </>
                    }
                    {modalTitle === "Patient Demographics" &&
                        <>
                            <form>
                                <div className="pd-top">
                                    <h4>Booking For</h4>
                                    <CustomRadioButton
                                        label="Self"
                                        value="self"
                                        setValue={setBookingFor}
                                        checked={bookingFor === "self"}
                                    />
                                    <div className="right">
                                        <CustomRadioButton
                                            label="Family Member"
                                            value="Family Member"
                                            setValue={setBookingFor}
                                            checked={bookingFor === "Family Member"}
                                        />
                                    </div>
                                </div>
                                <div className="pd-field">
                                    <label htmlFor="full_name">
                                        <h4>Full Name</h4>
                                    </label>
                                    <input type="text" placeholder="Full Name" className="form-control" />
                                </div>
                                <div className="pd-field">
                                    <label htmlFor="dob">
                                        <h4>DOB</h4>
                                    </label>
                                    <input type="date" className="form-control" />
                                </div>
                                <div className="pd-field">
                                    <label htmlFor="gender">
                                        <h4>Sex</h4>
                                    </label>
                                    <CustomRadioButton
                                        label="Male"
                                        value="male"
                                        setValue={setGender}
                                        checked={gender === "male"}
                                    />
                                    <div className="right">
                                        <CustomRadioButton
                                            label="Female"
                                            value="female"
                                            setValue={setGender}
                                            checked={gender === "female"}
                                        />
                                    </div>
                                </div>
                                {bookingFor === "Family Member" &&
                                    <div className="pd-field">
                                        <label htmlFor="relation">
                                            <h4>Relation</h4>
                                        </label>
                                        <div className="custom-select-container">
                                            <Select
                                                options={[
                                                    { value: '1', label: 'Family Member' },
                                                    { value: '2', label: 'Friend' },
                                                    { value: '3', label: 'Guardian' },
                                                ]}
                                                styles={selectStyles}
                                            />
                                        </div>
                                    </div>
                                }
                                <button className="btn_primary_gradient pd-btn">Submit</button>
                            </form>
                        </>
                    }
                    {modalTitle === "Prescription" &&
                        <div className="prescription">
                            <label
                                htmlFor="prescription"
                                className="custom-file-upload"
                                onClick={handleFileUploadClick}
                            >
                                <img src={require('../pages/images/upload.webp')} alt="upload" className="img-fluid" />
                                <h4>Drag an image here or <a className="upload-link">upload a file</a></h4>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="prescription"
                                    onClick={handleInputClick}
                                />
                            </label>
                        </div>
                    }
                </Modal.Body>
            </Modal >
        </div >
    )
}

export { BookingsPage }