import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { CustomRadioButton } from "../components/CustomRadioButton/CustomRadioButton";
import Select from 'react-select';
import { selectStyles } from "./StyleGuide";

const FamilyMembers = () => {
    const [modalTitle, setModalTitle] = useState('Add New Family Member');
    const [show, setShow] = useState(false);
    const [gender, setGender] = useState('');
    const onHide = () => {
        setShow(false);
    }
    return (
        <div className="patient-dashboard-child">
            <h3 className="desktop">Family Members</h3>
            <div className="form-container">
                <h4 className="add-new" onClick={() => setShow(true)}>+ Add New Family Member</h4>
                <div className="member-list">
                    <div className="member">
                        <span>01</span>
                        <div>
                            <h4 className="relation mb-0">Brother</h4>
                            <h4 className="mb-0">Kumar (M) 33Y</h4>
                        </div>
                    </div>
                    <div className="options">
                        <h4 className="status mb-0 active">Active</h4>
                        <HiOutlineDotsVertical size={20} />
                    </div>
                </div>
                <div className="member-list">
                    <div className="member">
                        <span>02</span>
                        <div>
                            <h4 className="relation mb-0">Brother</h4>
                            <h4 className="mb-0">Kumar (M) 33Y</h4>
                        </div>
                    </div>
                    <div className="options">
                        <h4 className="status mb-0 suspended">Suspended</h4>
                        <HiOutlineDotsVertical size={20} />
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h3>{modalTitle}</h3>
                    <IoIosCloseCircleOutline size={18} onClick={onHide} className="close-icon" />
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="pd-field">
                            <label htmlFor="full_name">
                                <h4>Full Name</h4>
                            </label>
                            <input type="text" placeholder="Full Name" className="form-control" />
                        </div>
                        <div className="pd-field">
                            <label htmlFor="dob">
                                <h4>DOB</h4>
                            </label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="pd-field">
                            <label htmlFor="gender">
                                <h4>Sex</h4>
                            </label>
                            <CustomRadioButton
                                label="Male"
                                value="male"
                                setValue={setGender}
                                checked={gender === "male"}
                            />
                            <div className="right">
                                <CustomRadioButton
                                    label="Female"
                                    value="female"
                                    setValue={setGender}
                                    checked={gender === "female"}
                                />
                            </div>
                        </div>
                        <div className="pd-field">
                            <label htmlFor="relation">
                                <h4>Relation</h4>
                            </label>
                            <div className="custom-select-container">
                                <Select
                                    options={[
                                        { value: '1', label: 'Family Member' },
                                        { value: '2', label: 'Friend' },
                                        { value: '3', label: 'Guardian' },
                                    ]}
                                    styles={selectStyles}
                                />
                            </div>
                        </div>
                        <button className="btn_primary_gradient pd-btn">Submit</button>
                    </form>
                </Modal.Body>
            </Modal >
        </div>
    )
}

export { FamilyMembers }