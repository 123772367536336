import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2, settings4 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const DoctorConsultation = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Doctor Consultation'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="your_health">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Your Health Matters</h2>
                            <h2>Wherever You Are</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/your_health_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="no_more">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/no_more.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>Where Love Meets Little Feet</h2>
                            <h3>No More Waiting Rooms</h3>
                            <h4>Just personalized care in the comfort of your own home. Our team understands that
                                every patient is unique, which is why we tailor our consultations to your specific needs
                                and schedule. From routine check-ups to urgent medical attention, we've got you covered</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="medicines">
                <div className="container">
                    <h2>Healing Begins When You Treat The Root Cause</h2>
                    <h3>Scope Of Expertise We Offer</h3>
                    <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings4}>
                        <div className="slide_item_parent">
                            <div className="slide_item">Internal Medicine</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Pediatrics</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Geriatrics</div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">Obstetrics</div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="say">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Sit Back & Relax</h2>
                            <h3>We’ll Be Right There</h3>
                            <h4>
                                Take the hassle out of healthcare with our convenient home doctor
                                consultation service. We understand that visiting a clinic or hospital
                                can be stressful and time consuming, especially when you're not
                                feeling well. That's why we bring quality medical care directly
                                to your doorstep.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/sit_back.webp')} className="img-fluid big_img" alt="choose" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="cover">
                <div className="container">
                    <h2>Who Requires Our Assistance</h2>
                    <div className="grid_5">
                        <h3 className="grid_item">Critically Ill</h3>
                        <h3 className="grid_item">Post Surgery</h3>
                        <h3 className="grid_item">Health Screening</h3>
                        <h3 className="grid_item">Palliative Care</h3>
                        <h3 className="grid_item">Bedridden Patients</h3>
                    </div>
                </div>
            </section>
            <section className="setting">
                <div className="container">
                    <h2>Unmatched Medical Excellence</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/expert_physicians.webp')} alt="one to one" className="img-fluid" />
                            <h4>Expert Physicians</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/patient.webp')} alt="one to one" className="img-fluid" />
                            <h4>Patient-Centered</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/inter_disciplinary.webp')} alt="one to one" className="img-fluid" />
                            <h4>Interdisciplinary Care</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/continuity.webp')} alt="one to one" className="img-fluid" />
                            <h4>Continuity Of Care</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>Discover Why Everyone's Talking About Us</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>“I've been using the home doctor consultation service for my elderly parents,
                                    who have trouble getting around. The doctors are always kind, patient, and
                                    thorough during their visits. It's been a game-changer for our family.”</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        {/* <p>Neurologist</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        {/* <p>Neurologist</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { DoctorConsultation }