import React, { useRef } from "react";
import { Footer } from "../components/Footer";
import { settings3 } from "./StyleGuide";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { settings, settings2 } from "./StyleGuide";
import { handleShow, setModalTitle, } from "../redux/Slices/ModalSlice";
import { useDispatch } from 'react-redux';

const Diagnostics = () => {
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalTitle('Diagnostics'));
        dispatch(handleShow());
    };
    return (
        <>
            <section className="diagnostics_top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Your Home, Our Expertise</h2>
                            <h2>Comfort Meets Precision Diagnostics</h2>
                            <button className="btn_primary_gradient" onClick={openModal}>Book Now</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/diagnostics_top_mobile.webp')} alt="icu" className="img-fluid mobile" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="compassion">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 left">
                            <img src={require('./images/from_lab.webp')} alt="icu" className="img-fluid big_img" />
                        </div>
                        <div className="col-md-6">
                            <h2>From Lab To Couch</h2>
                            <h4>Imagine having the convenience of laboratory-quality diagnostics without
                                ever leaving the comfort of your home. With our innovative service, we make
                                that a reality. Whether it's a simple blood draw or more complex diagnostics,
                                we ensure minimal discomfort and maximum accuracy.</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cover">
                <div className="container">
                    <h2>Our Repertoire Of Services</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Complete Blood Picture</h3>
                        <h3 className="grid_item">Blood Sugar Levels</h3>
                        <h3 className="grid_item">Liver Function Tests</h3>
                        <h3 className="grid_item">Kidney Function Tests</h3>
                        <h3 className="grid_item">Thyroid Function Tests</h3>
                        <h3 className="grid_item">Cardiac Markers</h3>
                        <h3 className="grid_item">Serum Electrolytes</h3>
                        <h3 className="grid_item">Lipid Profile</h3>
                    </div>
                    <div className="grid_3">
                        <h3 className="grid_item">Urinalysis</h3>
                        <h3 className="grid_item">Allergy Panel</h3>
                        <h3 className="grid_item">Vitamin Panel</h3>
                    </div>
                </div>
            </section>
            <section className="setting">
                <div className="container">
                    <h2>How We Function</h2>
                    <div className="grid_4">
                        <div className="grid_item">
                            <img src={require('./images/book_a.webp')} alt="one to one" className="img-fluid" />
                            <h4>Book A Test</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/sample.webp')} alt="one to one" className="img-fluid" />
                            <h4>Sample Pick Up</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/analysis.webp')} alt="one to one" className="img-fluid" />
                            <h4>Analysis</h4>
                        </div>
                        <div className="grid_item">
                            <img src={require('./images/download.webp')} alt="one to one" className="img-fluid" />
                            <h4>Download Reports</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="say trans_hc">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Precision in Every Sample</h2>
                            <h4>
                                We utilize the latest equipment and technology to perform our tests,
                                ensuring high precision and reliability in every analysis.Your health
                                and well-being are our top priorities. We know that time is of the
                                essence, which is why we strive to deliver results promptly, without
                                compromising on accuracy.
                            </h4>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/precision.webp')} className="img-fluid big_img" alt="say" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="premier pb-0">
                <div className="container">
                    <h2>Our Competitive Edge</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Certified Phlebotomist</h3>
                        <h3 className="grid_item">Quality Compliance</h3>
                        <h3 className="grid_item">Expert Verified</h3>
                        <h3 className="grid_item">NABL Accredited</h3>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="medicines">
                        <h2>Who May Benefit</h2>
                        <div className="grid_4">
                            <h3 className="grid_item">Elderly</h3>
                            <h3 className="grid_item">Chronically Ill</h3>
                            <h3 className="grid_item">Post Surgery Recovery</h3>
                            <h3 className="grid_item">Bedridden</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials">
                <div className="container">
                    <p className="title-blue">Testimonials</p>
                    <h2>See Why Everyone's Excited</h2>
                    <Slider {...settings}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>“As someone with a busy schedule, having the option to get my tests done
                                    at home was a game changer. The process was seamless, and the results were accurate. “</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/quotes.webp')} alt="quotes" />
                                <h4>Choosing home baby care was the best decision we made for our
                                    newborn. The nurses were amazing - they provided expert guidance,
                                    support, and care right in the comfort of our home. They helped us
                                    navigate the challenges of early parenthood, from ....</h4>
                                <div className="client">
                                    <img src={require('./images/doctor_2.webp')} alt="client" />
                                    <div>
                                        <h4>Pelican Steve</h4>
                                        <p>Neurologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="check">
                <div className="container">
                    <h2>Check Our Other Services</h2>
                    <Slider {...settings2}>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_nurse.webp')} alt="check" className="img-fluid" />
                                <h3>Home Nurse</h3>
                                <h4>
                                    With innovative technology and compassionate
                                    nursing, we're transforming the home into a
                                    place of healing, comfort, and independence.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/baby_care.webp')} alt="check" className="img-fluid" />
                                <h3>Baby Care</h3>
                                <h4>
                                    Our baby care services are designed to make
                                    the early days of parenthood easier and more
                                    enjoyable for you, providing reliable support
                                    and expert care for your newborn.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                        <div className="slide_item_parent">
                            <div className="slide_item">
                                <img src={require('./images/home_icu.webp')} alt="check" className="img-fluid" />
                                <h3>Home ICU Setup</h3>
                                <h4>
                                    Transforming healthcare with our innovative home
                                    ICU setup. Say goodbye to hospital stays and hello
                                    to comfort, convenience, and expert care tailored to your needs.
                                </h4>
                                <button className="btn_primary_gradient">Read More</button>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <Footer />
        </>
    );
};

export { Diagnostics }