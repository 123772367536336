import React from "react";
import { Footer } from "../components/Footer";
import { FaAnglesRight } from "react-icons/fa6";

const JoinOurNetwork = () => {
    return (
        <div>
            <section className="join_our">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Enhance Your Practice</h2>
                            <h2>Elevate Your Care</h2>
                            <button className="btn_primary_gradient">Lets Collaborate</button>
                        </div>
                        <div className="col-md-6 right">
                            <img src={require('./images/enhance_mobile.webp')} className="img-fluid mobile" alt="thrilled" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ride">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={require('./images/thrilled.webp')} className="img-fluid big_img" alt="thrilled" />
                        </div>
                        <div className="col-md-6 right">
                            <h2>Thrilled To Know That You’re Keen On Joining</h2>
                            <ul>
                                <li>
                                    <FaAnglesRight size={10} />
                                    Arush is an exceptional home healthcare solutions tailored to
                                    meet the unique needs of the users be it home nurse,
                                    caregiver, baby care, physiotherapy, doctor consultations, &
                                    so on
                                </li>

                                <li>
                                    <FaAnglesRight size={10} />
                                    We are a diverse network of health professionals who would
                                    ensure that our users have uninterrupted access to these home
                                    healthcare services
                                </li>

                                <li>
                                    <FaAnglesRight size={10} />
                                    Ours is a collaborative team based approach to health care
                                    with an opportunity to make a difference
                                </li>
                            </ul>
                            <button className="btn_primary_gradient">Lets Collaborate</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cover">
                <div className="container">
                    <h2>Why You Would Appreciate Collaborating With Us</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Tech Enabled Systems</h3>
                        <h3 className="grid_item">Work As Per Your Own Schedules</h3>
                        <h3 className="grid_item">Competitive Rates</h3>
                        <h3 className="grid_item">Safe, Secure, & Convenient</h3>
                    </div>
                </div>
            </section>

            <section className="premier">
                <div className="container">
                    <h2>Requirements</h2>
                    <div className="grid_4">
                        <h3 className="grid_item">Medically Certified </h3>
                        <h3 className="grid_item">Board Certification</h3>
                        <h3 className="grid_item">Possess State Licensure</h3>
                        <h3 className="grid_item">Contractual Agreement</h3>
                    </div>
                </div>
            </section>

            <section className="its">
                <div className="container">
                    <h3>It’s Even More Easier </h3>
                    <h4>
                        The process of joining our network of providers is now even easier.
                        Quick registration process followed by onboarding through
                        credentialing to contracting, and managing your expertise. Our
                        intuitive designs and personalized dashboards would ease your
                        management of appointments You can update your real-time
                        availability and work as per your preferred schedules.
                    </h4>
                    <h3>Collaborative Tools</h3>
                    <h4>
                        User-friendly collaborative tools i.e., care plans would allow you
                        to practice with ease. Patients can pay for their services through
                        the app. This would help you handle your financials and track
                        payments at your fingertips
                    </h4>
                </div>
            </section>

            <section>
                <div className="container text-center">
                    <h2>Earn Distinction</h2>
                    <h4>Users can rate their experiences and provide valuable feedback.
                        These satisfaction surveys can help you gain recognition for your work</h4>
                </div>
            </section>
            <Footer />
        </div>
    )
};

export { JoinOurNetwork }