import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem, clearCart } from '../redux/Slices/HealthSlice'; 

function Demo() {
    const items = useSelector((state) => state.healthData.items); 
    const totalQuantity = useSelector((state) => state.healthData.totalQuantity); 
    const totalPrice = useSelector((state) => state.healthData.totalPrice); 
    const dispatch = useDispatch();

    const handleAddItem = (item) => {
        dispatch(addItem(item));
    };

    const handleRemoveItem = (id) => {
        dispatch(removeItem(id));
    };

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    return (
        <div className="container">
            <h1>Healthcare Cart</h1> 
            <button className='btn btn-primary' onClick={() => handleAddItem({ id: 1, name: 'Item 1', price: 10 })}>
                Add Item 1
            </button>
            <button className='btn btn-secondary' onClick={() => handleAddItem({ id: 2, name: 'Item 2', price: 20 })}>
                Add Item 2
            </button>
            <button className='btn btn-default' onClick={() => handleClearCart()}>Clear Cart</button>
            <div>
                <h2>Health Cart Items</h2>
                <ul>
                    {items.map((item) => (
                        <li key={item.id}>
                            {item.name} - ${item.price} x {item.quantity} = ${item.totalPrice}
                            <button onClick={() => handleRemoveItem(item.id)}>Remove</button>
                        </li>
                    ))}
                </ul>
            </div>
            <h2>Total Quantity: {totalQuantity}</h2>
            <h2>Total Price: ${totalPrice}</h2>
        </div>
    );
}

export { Demo };
