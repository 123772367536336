import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import Logout from "./Utils/Logout";
import {
  Home, StyleGuide, Demo, HomeICU,
  HomeNurse, MedicineHomeDelivery,
  CareGiver, Radiology, BabyCareServices,
  DoctorConsultation, Physiotherapy,
  MedicalEquipment, Diagnostics,
  HomeHealthChecks, JoinOurNetwork,
  Profile, BookingsPage, FamilyMembers
} from "./pages";
import { NavTab } from "./components/Navbar";
import { PatientDashboard } from "./components/Patient Dashboard/PatientDashboard";
import './styles/styles.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NavTab />} >
          <Route index element={<Home />} />
          <Route path="/home-icu-setup" element={<HomeICU />} />
          <Route path="/home-nurse" element={<HomeNurse />} />
          <Route path="/medicine-home-delivery" element={<MedicineHomeDelivery />} />
          <Route path="/care-giver" element={<CareGiver />} />
          <Route path="/radiology" element={<Radiology />} />
          <Route path="/baby-care-services" element={<BabyCareServices />} />
          <Route path="/physiotherapy" element={<Physiotherapy />} />
          <Route path="/medical-equipment" element={<MedicalEquipment />} />
          <Route path="/doctor-consultation" element={<DoctorConsultation />} />
          <Route path="/diagnostics" element={<Diagnostics />} />
          <Route path="/home-health-checks" element={<HomeHealthChecks />} />
          <Route path="/join-our-network" element={<JoinOurNetwork />} />
          <Route path="/style_guide" element={<StyleGuide />} />
          <Route path="" element={<ProtectedRoute />} >
            <Route path="/" element={<PatientDashboard />} >
              <Route path="/patient-dashboard/profile" element={<Profile />} />
              <Route path="/patient-dashboard/bookings" element={<BookingsPage />} />
              <Route path="/patient-dashboard/family-members" element={<FamilyMembers />} />
            </Route>
            <Route path="/demo" element={<Demo />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
