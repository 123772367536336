import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./PatientDashboard.css";
import { useAuth } from "../../Utils/AuthContext";
import profileIcon from "../../pages/images/profile.svg";
import bookingsIcon from "../../pages/images/bookings.svg";
import familyIcon from "../../pages/images/family.svg";
import logoutIcon from "../../pages/images/logout.svg";
import Select from 'react-select';
import { selectStyles } from "../../pages/StyleGuide";
const PatientDashboard = () => {
    const navigate = useNavigate();
    const { setIsLoggedIn } = useAuth();
    const [activeUrl, setActiveUrl] = useState("");
    const [defaultValue, setDefaultValue] = useState({ value: "profile", label: "Profile" })
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
        setIsLoggedIn(false);
    }

    const handleNavigate = (url) => {
        navigate(url);
    }

    useEffect(() => {
        setActiveUrl(window.location.pathname);
        switch (window.location.pathname) {
            case "/patient-dashboard/profile":
                setDefaultValue({ value: "profile", label: "Profile" });
                break;
            case "/patient-dashboard/bookings":
                setDefaultValue({ value: "bookings", label: "Bookings" });
                break;
            case "/patient-dashboard/family-members":
                setDefaultValue({ value: "family-members", label: "Family Members" });
                break;
            case "logout":
                handleLogout();
                break;
            default:
                setDefaultValue({ value: "profile", label: "Profile" });
                break;
        }
    }, [window.location.pathname]);
    return (
        <div className="container patient-dashboard-parent">
            <div className="patient-dashboard desktop">
                <div className="img-container">
                    <img src={require("../../pages/images/profile.webp")} className="img-fluid" alt="profile" />
                </div>
                <div
                    className={`${activeUrl === "/patient-dashboard/profile" ? "active" : ""} dashboard-item`}
                    onClick={() => handleNavigate("/patient-dashboard/profile")}
                >
                    <img src={profileIcon} className="img-fluid" alt="profile-small" />
                    <span>Profile</span>
                </div>
                <div
                    className={`${activeUrl === "/patient-dashboard/bookings" ? "active" : ""} dashboard-item`}
                    onClick={() => handleNavigate("/patient-dashboard/bookings")}
                >
                    <img src={bookingsIcon} className="img-fluid" alt="bookings" />
                    <span>Bookings</span>
                </div>
                <div
                    className={`${activeUrl === "/patient-dashboard/family-members" ? "active" : ""} dashboard-item`}
                    onClick={() => handleNavigate("/patient-dashboard/family-members")}
                >
                    <img src={familyIcon} className="img-fluid" alt="family" />
                    <span>Family Members</span>
                </div>
                <div className={"dashboard-item"} onClick={() => handleLogout()}>
                    <img src={logoutIcon} className="img-fluid" alt="logout" />
                    <span>Logout</span>
                </div>
            </div>
            <div className="mobile">
                <Select 
                    styles={selectStyles}
                    options={[
                        { value: '/patient-dashboard/profile', label: 'Profile' },
                        { value: '/patient-dashboard/bookings', label: 'Bookings' },
                        { value: '/patient-dashboard/family-members', label: 'Family Members' },
                        { value: 'logout', label: 'Logout' }
                    ]}
                    value={defaultValue}
                    onChange={(e) => handleNavigate(e.value)}
                    fontSize="20px"
                />
            </div>
            <Outlet />
        </div>
    );
}

export { PatientDashboard }