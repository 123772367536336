import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import './Navbar.css';
import { IoLocationOutline, IoArrowBack } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { AiOutlineLogin } from "react-icons/ai";
import { BsChevronCompactDown } from "react-icons/bs";
import { useAuth } from "../../Utils/AuthContext";
import { useSelector, useDispatch } from 'react-redux';
import { handleClose, handleShow, setModalTitle } from "../../redux/Slices/ModalSlice";
import { Booking } from "../Booking";

const NavTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.show);
    const modalTitle = useSelector((state) => state.modal.modalTitle);

    const openModal = () => {
        dispatch(setModalTitle('Login'));
        dispatch(handleShow());
    };

    const closeModal = () => {
        dispatch(handleClose());
    };
    const handleNavigate = (url) => {
        window.scrollTo(0, 0);
        navigate(url);
    }

    const handleLogin = () => {
        if (isLoggedIn) {
            navigate('/patient-dashboard/profile');
        } else {
            openModal();
        }
    };

    const { isLoggedIn } = useAuth();
    return (
        <div>
            <Booking />
            <nav className="navbar sticky-top navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand desktop" href="/">
                        <img className="logo" src={require('../../images/hhc_brand.png')} alt="brand" />
                    </a>
                    {window.location.pathname !== '/' &&
                        <IoArrowBack className="mobile-back" size={20} onClick={() => navigate(-1)} />
                    }
                    <div className="mobile-location-box">
                        <IoLocationOutline size={20} />
                        <input type="text" placeholder="Banjara Hills, Hyderabad" />
                        <BsChevronCompactDown />
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    <div className="mobile-right">
                        <CiSearch size={20} />
                        {isLoggedIn ?
                            <span className="profile mobile" onClick={handleLogin}>
                                <img className="img-fluid" src={require('../../pages/images/profile.webp')} />
                            </span>
                            :
                            <AiOutlineLogin size={30} color="#545454" onClick={handleLogin} />
                        }
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-between">
                            <li className="nav-item">
                                <div className="location-box">
                                    <IoLocationOutline size={20} />
                                    <input type="text" placeholder="Banjara Hills, Hyderabad" />
                                    <BsChevronCompactDown />
                                </div>
                            </li>
                            <li className="nav-item search-container">
                                <div className="search-box">
                                    <CiSearch size={20} />
                                    <input type="text" placeholder="Search" />
                                </div>
                            </li>
                            <li className="nav-item">
                                <button className="btn_secondary_2" style={{ marginRight: '24px' }} onClick={() => handleNavigate('/join-our-network')}>Join Our Network</button>
                            </li>
                            <li className="nav-item" onClick={handleLogin}>
                                {isLoggedIn ?
                                    <span className="profile">
                                        <img className="img-fluid" src={require('../../pages/images/profile.webp')} />
                                    </span>
                                    :
                                    <button className="btn_secondary_2">Login</button>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
        </div>
    );
};

export { NavTab }