import React from 'react'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from './AuthContext';

const ProtectedRoute = () => {
    const { isLoggedIn } = useAuth();
    let location = useLocation();

    return isLoggedIn
        ? <Outlet />
        : <Navigate to="/" replace state={{ from: location }} />;

};

export default ProtectedRoute;